// btn
/*== 背景が流れる（右から左） */
.-isAnim_bgRight {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: clr(main);
    width: 100%;
    height: 100%;
    /*アニメーション*/
    transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
    transform: scale(0, 1);
    transform-origin: left top;
  }
  .text {
    position: relative;
    z-index: 2;
  }
  /*hoverした際の形状*/
  &:hover {
    .text {
      color: white;
    }
    &:before {
      transform-origin: right top;
      transform: scale(1, 1);
    } //before
  } //hover
} //isAnim_bgright

.-isAnim_rotate {
  &:hover {
    &.-deco:before,
    &.-deco::after,
    .-deco:before,
    .-deco::after {
      transition: $base-duration;
    }
    &.-deco:before,
    .-deco:before {
      transform: rotate(360deg);
    }
    &.-deco::after,
    .-deco::after {
      transform: rotate(270deg);
    }
  }
} //isAnim_rotate
.-isAnim_zoom {
  .zoomPicWrap {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      transition: all, $base-duration;
    }
  } //zoomPicWrap
  &:hover {
    .zoomPicWrap {
      img {
        transform: scale(1.2);
      }
    } //zoomPicWrap
  }
} //isAnim_zoom

.-isAnim_bgOverlay {
  position: relative;
  .-bgOverlayItem {
    display: block;
    &:after {
      @include mq-pc {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #2b2b2b;
        opacity: 0.44;
        display: block;
        width: 100%;
        height: 100%;
        transition-duration: 0.5s;
        z-index: 1;
      }
    }
  }
  .-bgOverlayItemTxt {
    color: clr(text);
    transition-duration: 0.5s;
    &.-pcColor {
      @include mq-pc {
        color: white;
      }
    }
  }

  &:hover {
    .-bgOverlayItem {
      &:after {
        opacity: 0;
      }
    }
    .-bgOverlayItemTxt {
      @include mq-pc {
        color: clr(text);
      }
    }
  }
} //isAnim_bgOverlay
.-isAnim_bgOverlay_dark {
  position: relative;
  .-bgOverlayItem {
    display: block;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      // background-color: #2b2b2b;
      background: linear-gradient(to top, #000, rgba(154, 154, 154, 0.1));
      opacity: 0;
      display: block;
      width: 100%;
      height: 100%;
      transition-duration: 0.5s;
      z-index: 1;
      @include mq-pc {
        opacity: 0;
      } //mq-pc
      @include mq-sp {
        opacity: 0.7;
      } //mq-sp
    }
  }
  .-bgOverlayItemTxt {
    color: clr(text);
    transition-duration: 0.5s;
    &.-pcColor {
      @include mq-pc {
        color: white;
      }
    }
  }

  &:hover {
    .-bgOverlayItem {
      &:after {
        opacity: 0.8;
      }
    }
    .-bgOverlayItemTxt {
      @include mq-pc {
        color: clr(text);
      }
    }
  }
} //isAnim_bgOverlay_dark
