@charset "UTF-8";

/* line-clamp
============================================================= */
.-line {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  &.-clamp {
    &01 {
      -webkit-line-clamp: 1;
    }
    &02 {
      -webkit-line-clamp: 2;
    }
    &03 {
      -webkit-line-clamp: 3;
    }
  }
} //-line-clamp
