@charset "UTF-8";

/* hoverShadow
============================================================= */

%shadowItem {
  @include mq-max {
    box-shadow: rem(15) rem(15) clr(green-main);
  }
  @include mq-pc {
    box-shadow: vw(15) vw(15) clr(green-main);
  }
}
.hover-effect {
  &.is-shadow {
    &:hover {
      .-shadowItem {
        @extend %shadowItem;
      }
    } //hover
    .-shadowItem {
      transition: all, 0.5s;
    }
  }
} //hover-effect
