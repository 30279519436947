/** 
 * ========================================
 * list styles
 * ========================================
 */
$listNumCircleColorMainSize_pc: 2.5;

.listStyleNon {
  list-style: none;
  li {
    list-style: none;
    padding-left: 0;
    &:before {
      display: none;
    }
  }
}
ul {
  counter-reset: cntOl;
  li {
    list-style-type: none;
    font-weight: inherit;
  }
  &.disc {
    li {
      position: relative;
      @include mq-pc {
        padding-left: rem(7+3+ (3/2));
      } //pc
      @include mq-sp {
        padding-left: svw(7+3+ (3/2));
      } //sp
      &::before {
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        background-color: clr(text);
        content: "";
        @include mq-pc {
          width: rem(3);
          height: rem(3);
          left: rem(3/2);
          margin-top: rem(14);
        } //pc
        @include mq-sp {
          width: svw(3);
          height: svw(3);
          left: svw(3/2);
          margin-top: svw(12);
        } //sp
      } //before
    } //li
    &ColorMain {
      @extend .disc;
      li::before {
        background-color: clr(txtBase);
      }
    }
    &.-headerNavAccent {
      li {
        &::before {
          display: inline-block;
          position: absolute;
          margin-top: rem(1);
          content: "";
          width: rem(6);
          height: rem(6);
          background-color: clr(white);
          border: 1px solid #adadad;
        }
      }
    }
    &.-supplement {
      li {
        @include mq-pc {
          font-size: rem(14);
        } //pc
        @include mq-sp {
          font-size: svw(13);
        } //sp
      } //li
    } //-supplement
  }
  &.precautions {
    li {
      position: relative;
      @include mq-pc {
        padding-left: 2.5em;
      }
      @include mq-sp {
        padding-left: 1.25em;
      }
      &::before {
        display: inline-block;
        position: absolute;
        content: "※";
        color: clr(plan-list);
        @include mq-pc {
          width: vw(3);
          height: vw(3);
          left: 0.8em;
        } //pc
        @include mq-max {
          width: rem(3);
          height: rem(3);
        } //wide

        @include mq-sp {
          width: svw(3);
          height: svw(3);
          left: 0;
        } //sp
      }
    }
  } //precautions
  &.planList {
    li {
      position: relative;
      padding-left: 1.25em;
      &::before {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        border-radius: 50%;
        background-color: clr(plan-list);
        content: "";
        @include mq-pc {
          width: vw(3);
          height: vw(3);
        } //pc
        @include mq-max {
          width: rem(3);
          height: rem(3);
        } //wide

        @include mq-sp {
          width: svw(3);
          height: svw(3);
        } //sp
      }
    }
  } //planList
}
ol {
  counter-reset: cntOl;
  li {
    position: relative;

    list-style-type: none;
    font-weight: inherit;
    @include mq-pc {
      padding-left: rem(100);
      font-size: rem(28);
    } //pc
    @include mq-sp {
      // padding-left: svw(10);
      font-size: svw(22);
    } //sp
    &::before {
      position: absolute;
      display: inline-block;
      left: 0;
      color: inherit;
      font-family: $enfont;
      font-weight: 500;
      white-space: nowrap;
      letter-spacing: 0.1em;
      counter-increment: cntOl;
      // content: counter(cntOl) ".";
      content: "0" counter(cntOl);
      @include mq-pc {
        margin-top: -0.15em;
      } //pc
      @include mq-sp {
        margin-top: -0.4em;
      } //sp
    }
  } //li
  &.listNumColorMain {
    li::before {
      color: clr(sub);
    }
  }
  &.listNumCircleColorMain {
    li::before {
      width: rem($listNumCircleColorMainSize_pc);
      height: rem($listNumCircleColorMainSize_pc);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: clr(txtBase);
      color: clr(white);
      content: counter(cntOl);
    }
  }
  &.listParentheses {
    margin: 0;
    li {
      list-style-type: none;
      list-style-position: inside;
      counter-increment: cnt;
      &:before {
        display: marker;
        content: "(" counter(cnt) ") ";
      }
    }
  }
  &.listEn {
    counter-reset: en;
    li {
      counter-increment: en;
      left: -0.75em;
      &::before {
        content: counter(en, upper-alpha);
      }
    }
  }
}
