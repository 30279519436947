@charset "UTF-8";

/* ------------------------------------------------------------------
 sideContents
------------------------------------------------------------------ */
.sideContents {
  @include mq-sp {
    &.-arrowDown {
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-left: svw(10) solid transparent;
        border-right: svw(10) solid transparent;
        border-top: svw(16) solid clr(txt);
        top: 50%;
        transform: translateY(-50%);
        right: svw(20);
      } //after
    } //arrowDown
    &.-arrowUp {
      &:after {
        margin-top: svw(-25/2);
        border-bottom: svw(16) solid clr(txt);
        border-top: svw(16) solid transparent !important;
      } //after
    } //arrowUp
  } //sp
  &.-boxList {
    @include mq-sp {
      display: flex;
      margin-left: svw(-69/2);
      margin-right: svw(-69/2);
      overflow: hidden;
    } //sp
  } //boxList

  &.-box {
    @include mq-sp {
      width: calc(100% / 2);
      padding-left: svw(69/2);
      padding-right: svw(69/2);
    }
    &:not(:last-child) {
      @include mq-pc {
        margin-bottom: rem(80);
      } //pc
    } //:not(:last-child)
  } //-box
  &.-title {
    @include mq-pc {
      font-size: rem(16);
      margin-bottom: rem(40);
    } //pc
    @include mq-sp {
      width: 100%;
      text-align: left;
      font-size: svw(34);
      padding-bottom: svw(10);
      border-bottom: svw(2) solid #000;
    } //sp
  } //title
  &.-list {
    @include mq-pc {
      padding-left: rem(5);
      border-left: rem(1) solid #707070;
    } //pc
    @include mq-sp {
      margin-top: svw(30);
      background-color: blue;
      padding-bottom: svw(30);
    } //sp
  } //list
  &.-link {
    color: clr(gray);
    line-height: 1;
    @include mq-pc {
      font-size: rem(16);
    } //pc
    @include mq-sp {
      font-size: svw(34);
    } //sp
  } //-link

  &.-item {
    @include mq-pc {
      line-height: 1;
    } //pc
    &:not(:last-child) {
      @include mq-pc {
        margin-bottom: rem(20);
      } //pc
      @include mq-sp {
        margin-bottom: svw(20);
      } //sp
    } //:not(:last-child)
    &.-current {
      .-link {
        color: clr(main);
      } //-link
    } //current
  } //item
} //sideContents
