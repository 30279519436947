//--------------------------------------------------------------
// tsumiki用CSS
//
//
//
//
//--------------------------------------------------------------
@charset "UTF-8";

$full: 1000px;
$medium: 768px;
$small: 480px;

@mixin medium {
  @media (max-width: ($medium)) {
    @content;
  }
}

@mixin small {
  @media (max-width: ($small)) {
    @content;
  }
}
.tsumiki {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 90%;
  margin: 0 auto 30px;

  > div {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  //見出し
  &-headline {
    width: 100%;
    font-size: 150%;
    font-weight: bold;
  }

  //画像大+画像大キャプション
  // &-images-large {
  //   width: 100%;
  //   figure {
  //     width: 100%;
  //     margin: 0 auto;
  //     img {
  //       width: 100%;
  //     }
  //     figcaption {
  //       margin-top: 5px;
  //     }
  //   }
  // }

  //画像中+画像中キャプション
  // &-images-medium {
  //   width: 49%;
  //   margin: 0 0.5%;
  //   figure {
  //     width: 100%;
  //     margin: 0 auto;
  //     img {
  //       width: 100%;
  //     }
  //     figcaption {
  //       margin-top: 5px;
  //     }
  //   }
  // }

  //画像小+画像小キャプション
  // &-images-small {
  //   width: 33%;
  //   margin: 0 0.16%;
  //   figure {
  //     width: 100%;
  //     margin: 0 auto;
  //     img {
  //       width: 100%;
  //     }
  //     figcaption {
  //       margin-top: 5px;
  //     }
  //   }
  // }

  //フルサイズテキスト
  &-text {
    // font-size: 110%;
  }

  //イメージ+テキスト
  // &-images-text {
  //   display: flex;
  //   figure {
  //     width: 49%;
  //     margin-right: 2%;
  //   }

  //   p {
  //     width: 49%;
  //     // font-size: 110%;
  //   }
  // }

  //テキスト+イメージ
  // &-text-images {
  //   display: flex;
  //   p {
  //     width: 49%;
  //     margin-right: 2%;
  //     // font-size: 110%;
  //   }

  //   figure {
  //     width: 49%;
  //   }
  // }

  //テキスト+イメージ
  // &-text-text {
  //   display: flex;
  //   width: 100%;
  //   p {
  //     // font-size: 110%;
  //     width: 49%;
  //     margin-right: 2%;
  //     &:last-child {
  //       margin-right: 0;
  //     }
  //   }
  // }

  &-link {
    width: 100%;
    a {
      text-decoration: underline;
    }
  }

  &-space {
    width: 100%;
    height: 20px;
  }

  //ライン
  &-border-line {
    width: 100%;
    height: 1px;
    border-top: dotted 2px rgba(0, 0, 0, 1);
  }

  @include medium {
    > div {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    //見出し
    &-headline {
      width: 100%;
      font-size: 150%;
      font-weight: bold;
    }

    //画像大+画像大キャプション
    &-images-large {
      width: 100%;
      figure {
        width: 100%;
        margin: 0 auto;
        img {
          width: 100%;
        }
        figcaption {
          margin-top: 5px;
        }
      }
    }

    //画像中+画像中キャプション
    &-images-medium {
      width: 100%;
      margin: 0 0.5%;
      figure {
        width: 100%;
        margin: 0 auto;
        img {
          width: 100%;
        }
        figcaption {
          margin-top: 5px;
        }
      }
    }

    //画像小+画像小キャプション
    &-images-small {
      width: 100%;
      margin: 0 0.16%;
      figure {
        width: 100%;
        margin: 0 auto;
        img {
          width: 100%;
        }
        figcaption {
          margin-top: 5px;
        }
      }
    }

    //フルサイズテキスト
    &-text {
      // font-size: 110%;
    }

    //イメージ+テキスト
    &-images-text {
      display: block;
      figure {
        width: 100%;
        margin-bottom: 20px;
      }

      p {
        width: 100%;
        // font-size: 110%;
      }
    }

    //テキスト+イメージ
    &-text-images {
      display: block;
      p {
        width: 100%;
        margin-bottom: 20px;
        // font-size: 110%;
      }

      figure {
        width: 100%;
      }
    }

    //テキスト+イメージ
    &-text-text {
      display: block;
      width: 100%;
      p {
        // font-size: 110%;
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-link {
      width: 100%;
      a {
        text-decoration: underline;
      }
    }

    &-space {
      width: 100%;
      height: 20px;
    }

    //ライン
    &-border-line {
      width: 100%;
      height: 1px;
      border-top: dotted 2px rgba(0, 0, 0, 1);
    }
  }

  @include small {
    > div {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    //見出し
    &-headline {
      width: 100%;
      font-size: 150%;
      font-weight: bold;
    }

    //画像大+画像大キャプション
    &-images-large {
      width: 100%;
      figure {
        width: 100%;
        margin: 0 auto;
        img {
          width: 100%;
        }
        figcaption {
          margin-top: 5px;
        }
      }
    }

    //画像中+画像中キャプション
    &-images-medium {
      width: 100%;
      margin-right: 0;
      figure {
        width: 100%;
        margin: 0 auto;
        img {
          width: 100%;
        }
        figcaption {
          margin-top: 5px;
        }
      }

      & + .tsumiki-images-medium {
        margin-right: 0;
      }
    }

    //画像小+画像小キャプション
    &-images-small {
      width: 100%;
      margin-right: 0;
      figure {
        width: 100%;
        margin: 0 auto;
        img {
          width: 100%;
        }
        figcaption {
          margin-top: 5px;
        }
      }

      & + .tsumiki-images-small + .tsumiki-images-small {
        margin-right: 0;
      }
    }

    //フルサイズテキスト
    &-text {
      // font-size: 110%;
    }

    //イメージ+テキスト
    &-images-text {
      display: block;
      figure {
        width: 100%;
        margin-bottom: 20px;
      }

      p {
        width: 100%;
        // font-size: 110%;
      }
    }

    //テキスト+イメージ
    &-text-images {
      display: block;
      p {
        width: 100%;
        margin-bottom: 20px;
        // font-size: 110%;
      }

      figure {
        width: 100%;
      }
    }

    //テキスト+イメージ
    &-text-text {
      display: block;
      width: 100%;
      p {
        // font-size: 110%;
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-link {
      width: 100%;
      a {
        text-decoration: underline;
      }
    }

    &-space {
      width: 100%;
      height: 20px;
    }

    //ライン
    &-border-line {
      width: 100%;
      height: 1px;
      border-top: dotted 2px rgba(0, 0, 0, 1);
    }
  }
}
