/*
 * pagetop
 * ----------------------------------
 */
.pagetop {
  opacity: 0;
  position: fixed;
  right: 10px;
  bottom: -50px;
  z-index: 99;
  transition: all 0.3s ease;
  transition-property: opacity, bottom;
  @include mq-sp {
    transform: scale(0.75);
    transform-origin: right bottom;
  }

  &.is-appear {
    opacity: 1;
    bottom: 10px;
  }
  &.is-footer {
    position: absolute;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: clr(pageTopBg);
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-10%);
    }
  }
  .icon-arrow {
    position: unset;
    border: 2px solid;
    margin-top: 4px;
  }
  .text {
    display: none;
    color: clr(white);
    font-size: 0.1rem;
    line-height: 1.2;
    text-align: center;
  }
}
