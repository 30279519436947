@charset "UTF-8";

/* txtOverflow
============================================================= */

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: unset;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.line-clamp {
  // 複数行
  &.-line02 {
    -webkit-line-clamp: 2;
  }
  &.-line03 {
    -webkit-line-clamp: 3;
  }
  &.-line04 {
    -webkit-line-clamp: 4;
  }
  @include mq-pc {
    &.-line02_pc {
      -webkit-line-clamp: 2;
    }
    &.-line03_pc {
      -webkit-line-clamp: 3;
    }
    &.-line04_pc {
      -webkit-line-clamp: 4;
    }
  }
  @include mq-sp {
    &.-line02_sp {
      -webkit-line-clamp: 2;
    }
    &.-line03_sp {
      -webkit-line-clamp: 3;
    }
    &.-line04_sp {
      -webkit-line-clamp: 4;
    }
  }
}
