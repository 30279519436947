@charset "UTF-8";

/* other
============================================================= */

/* tel */

@include mq-pc {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
} //pc

/* object fit image */

img.objectFit {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &.-cover {
    font-family: "object-fit: cover;";
    object-fit: cover;
  }

  &.-contain {
    font-family: "object-fit: contain;";
    object-fit: contain;
  }
}

/* googleMap */

.googleMap {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

/* moreLink */

/* figure */

.figure {
  position: relative;

  figcaption {
    position: absolute;
    top: 0;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);

    @include mq-pc {
      font-size: rem(11);
      left: calc(-1em + #{rem(-11)});
    }

    @include mq-sp {
      font-size: vw(10);
      left: calc(-1em + #{vw(-11)});
    }
  }

  &.-captionRightBottom {
    figcaption {
      bottom: 0;
      top: auto;
      left: auto;

      @include mq-pc {
        right: calc(-1em + #{rem(-11)});
      }

      @include mq-sp {
        right: calc(-1em + #{vw(-11)});
      }
    }
  }

  &.-captionRightTop {
    figcaption {
      left: auto;

      @include mq-pc {
        right: calc(-1em + #{rem(-11)});
      }

      @include mq-sp {
        right: calc(-1em + #{vw(-11)});
      }
    }
  }
}

/* paragraphWrap */

.paragraphWrap {
  > p {
    &:not(:last-child) {
      @include mq-pc {
        margin-bottom: rem(20);
      }

      @include mq-sp {
        margin-bottom: vw(16);
      }
    }
  }
}
/* link underLine */
.-link {
  // @include fadeover;
}
.-underLine {
  border-bottom: 1px solid clr(gray);
  &.-black {
    border-color: black;
  }
}
/* 縦組み */
.-vertical-rl {
  writing-mode: vertical-rl;
}
// telボタン
/*pcの時*/
@include mq-pc {
  a[href^="tel:"] {
    pointer-events: none;
  }
}
@include mq-sp {
  /*spの時*/
  a[href^="tel:"] {
    pointer-events: auto;
  }
}
