/** 
 * ========================================
 * list topicsItem
 * ========================================
 */
.topiceItem {
  &.-list {
    display: flex;
    flex-wrap: wrap;
    @include mq-pc {
      margin: rem(-(32/2)) rem(-(40/2));
    } //pc
    @include mq-sp {
      margin: svw(-(20/2));
    } //sp
  } //list
  &.-item {
    @include mq-pc {
      width: calc(100% / 3);
      padding: rem(32/2) rem(40/2);
    } //pc
    @include mq-sp {
      width: calc(100% / 2);
      padding: svw(20/2);
    } //sp
  } //-item
  &.-itemWrap {
    display: flex;
    flex-direction: column;
  } //-itemWrap
  &.-dateDateWrap {
    display: flex;
    justify-content: space-between;
    line-height: 1.3;
    @include mq-pc {
      margin-top: rem(10);
      margin-bottom: rem(7);
      font-size: rem(12);
    } //pc
    @include mq-sp {
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: svw(5);
      margin-bottom: svw(5);
      font-size: svw(12);
    } //sp
  } //dateDateWrap
  &.-date {
    @include mq-pc {
      font-size: rem(12);
    } //pc
    @include mq-sp {
      font-size: svw(12);
    } //sp
  } //date
} //topiceItem
.categoryItem {
  &.-item {
    @include mq-pc {
      font-size: rem(12);
      margin-left: rem(5);
      text-align: right;
    } //pc
    @include mq-sp {
      margin-top: svw(4);
      font-size: svw(12);
      margin-right: svw(10);
    } //sp
  } //item
  &.-itemWrap {
    @include mq-pc {
      width: rem(181);
      padding-left: rem(18);
      justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
    } //pc
  } //itemWrap
} //categoryItem
.categoryItem {
  &.-itemListWrap {
    @include mq-pc {
      margin-bottom: rem(47);
    } //pc
    @include mq-sp {
      margin-bottom: svw(44);
    } //sp
  } //itemListWrap
  &.-itemList {
    display: flex;
    flex-wrap: wrap;
    @include mq-pc {
      margin: rem(-10 / 2);
    } //pc
    @include mq-sp {
      margin: svw(-10 / 2);
    } //sp
    .-item {
      line-height: 1.25;
      transition: all 0.3s;
      margin-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq-pc {
        border: rem(1) solid clr(green-main);
        padding: rem(1) rem(10) rem(3);
        margin: rem(10/2);
        font-size: rem(14);
      } //pc
      @include mq-sp {
        border: svw(1) solid clr(green-main);
        padding: svw(2) svw(10/2) svw(5);
        margin-top: svw(10/2);
        margin-bottom: svw(10/2);
        font-size: svw(14);
      } //sp
      &:hover {
        background-color: clr(green-main);
        color: white;
      } //hover
    } //categoryItem
    .-current {
      .categoryItem {
        background-color: clr(green-main);
        color: white;
      } //categoryItem
    } //current
  } //-itemList
} //categoryItem
