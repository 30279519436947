/*==================================================
　btn linkBtn左から右に線が縮む
===================================*/
.linkBtn {
  &.-wrap {
    display: flex;
    flex-wrap: wrap; /*スマホ表示折り返し用なのでPCのみなら不要*/
  } //-wrap

  &.-link {
    display: flex;
    position: relative;
    justify-content: flex-end;
    @include mq-sp {
      margin-left: auto;
    } //sp
    &.-viewMore {
      @include mq-max {
        width: rem(222.8);
      } //wide
      @include mq-pc {
        width: rem(222.8);
      } //pc
      @include mq-sp {
        width: svw(178.46);
      } //sp
    } //viewMore
    &.-googleMap {
      @include mq-max {
        width: rem(237.93);
      } //wide
      @include mq-pc {
        width: rem(237.93);
      } //pc
      @include mq-sp {
        width: svw(207.57);
      } //sp
    } //googleMap
    &:before {
      content: "";
      /*絶対配置で線の位置を決める*/
      position: absolute;
      bottom: 0;
      right: 0;
      /*線の形状*/
      width: 100%;
      height: 1px;
      background: clr(linkBtnAccent);
      /*アニメーションの指定*/
      transition: all 0.3s;
      transform-origin: right top; /*左上基点*/
      transform: scale(1, 1); /*X方向に0.2スケール拡大*/
    } //before
    &:hover {
      &:before {
        transform: scale(0.57, 1); /*X方向にスケール拡大*/
      }
    }
  } //-link
  &.-img {
    display: flex;
    background-color: white;
    position: relative;
    z-index: 1;
    @include mq-max {
      padding-left: rem(12);
    } //wide
    @include mq-pc {
      padding-left: rem(12);
    } //pc
    @include mq-sp {
      padding-left: svw(5.9);
    } //sp
    &.-viewMore {
      @include mq-max {
        width: rem((102.8)+12);
      } //wide
      @include mq-pc {
        width: rem((102.8)+12);
      } //pc
      @include mq-sp {
        width: svw(88.02);
      } //sp
    } //-viewMore
    &.-googleMap {
      @include mq-max {
        width: rem((115.92)+12);
      } //wide
      @include mq-pc {
        width: rem((115.92)+12);
      } //pc
      @include mq-sp {
        width: svw(102.22);
      } //sp
    } //-googleMap
  } //-img
  &.-text {
    display: flex;
    background-color: white;
    position: relative;
    z-index: 1;
    line-height: 1;
    @include mq-pc {
      font-size: rem(14);
    } //pc
    @include mq-max {
      font-size: rem(14);
    } //wide
    @include mq-sp {
      font-size: svw(14);
    } //sp
  } //text

  img {
    width: 100%;
  }
} //linkBtn
.boxBtnItem {
  &.-center {
    justify-content: center;
  } //-center
  &.-item {
    display: inline-flex;
    align-items: center;
    @include mq-sp {
      width: 100%;
    } //sp
  } //item
  &.-text {
    // line-height: 1.3;
    letter-spacing: 0.1em;
    @include mq-pc {
      font-size: rem(14);
    } //pc
    @include mq-sp {
      font-size: svw(14);
    } //sp
    &.-green {
      color: clr(green-main);
    }
  } //text

  &.-solid {
    &.-white {
      border: 1px solid clr(white);
      transition: all 0.5s;
      .-text {
        color: clr(white);
      } //text
      &:hover {
        background-color: clr(white);
        .-text {
          color: clr(green-main);
        } //text
      } //hover
    } //white
    &.-purple {
      border: 1px solid clr(purple-main);
      transition: all 0.5s;
      &:hover {
        border: 1px solid clr(purple-main);
        background-color: clr(purple-main);
        .-text {
          color: clr(white);
        } //text
      } //hover
    } //purple
    &.-green {
      border: 1px solid clr(green-main);
      transition: all 0.5s;
      &:hover {
        border: 1px solid clr(green-main);
        background-color: clr(green-main);
        .-text {
          color: clr(white);
        } //text
      } //hover
    } //green
    &.-black {
      border: 1px solid clr(black-light);
      transition: all 0.5s;
      &:hover {
        border: 1px solid clr(black-light);
        background-color: clr(black-light);
        .-text {
          color: clr(white);
        } //text
      } //hover
    } //black
  } //-solid
  &.-bgColor {
    .-text {
      @include mq-pc {
        font-size: rem(11);
      } //pc
      @include mq-sp {
        font-size: svw(11);
      } //sp
      &.-white {
        color: clr(white);
      } //white
    } //text
    &.-green {
      background-color: clr(green-main);
      border: 1px solid clr(green-main);
      transition: all 0.5s;
      &:hover {
        background-color: clr(white);
        .-text {
          &.-white {
            color: clr(green-main);
          } //white
        } //text
      } //hover
    } //-green
    &.-purple {
      background-color: clr(purple-main);
      border: 1px solid clr(purple-main);
      transition: all 0.5s;
      &:hover {
        background-color: clr(white);
        .-text {
          &.-white {
            color: clr(purple-main);
          } //white
        } //text
      } //hover
    } //-purple
  } //-bgColor
  &.-link {
    display: flex;
    &.-left {
      @include mq-pc {
        padding: rem(1) rem(33) rem(1) rem(15);
      } //pc
      @include mq-sp {
        padding: svw(7) svw(33) svw(5) svw(15);
      } //sp
    } //-left
    &.-center {
      justify-content: center;
      @include mq-pc {
        padding: rem(18.5) rem(30);
      } //pc
      @include mq-sp {
        padding: svw(10) svw(30);
      } //sp
    } //-center
    &.-w60per_sp {
      @include mq-sp {
        width: 60%;
      } //sp
    } //-w60per_sp
    &.-w100per_sp {
      @include mq-sp {
        width: 100%;
      } //sp
    } //-w100per_sp
    &.-big {
      @include mq-pc {
        padding: rem(20);
      } //pc
      @include mq-sp {
        padding: svw(20);
      } //sp
      .-text {
        line-height: 1.3;
        letter-spacing: 0.15em;
        @include mq-pc {
          font-size: rem(16);
        } //pc
        @include mq-sp {
          font-size: svw(16);
        } //sp
      } //text
    } //-big
    &.-aboutPageNav {
      @include mq-pc {
        padding-left: rem(30);
      } //pc
      @include mq-sp {
        padding-left: svw(10);
        padding-right: svw(40);
      } //sp
    } //aboutPageNav
    &.-reserve {
      @include mq-pc {
        width: rem(280);
      } //pc
      @include mq-sp {
        width: svw(200);
      } //sp
      .-text {
        white-space: nowrap;
        letter-spacing: 0.15em;
        @include mq-pc {
          font-size: rem(16);
        } //pc
        @include mq-sp {
          font-size: svw(16);
        } //sp
      } //text
    } //-reserve
    &.-commonReserve {
      @include mq-pc {
        width: rem(250);
      } //pc
      @include mq-sp {
        width: svw(250);
      } //sp
      .-text {
        line-height: 1;
        white-space: nowrap;
        @include mq-pc {
          font-size: rem(16);
        } //pc
        @include mq-sp {
          font-size: svw(16);
        } //sp
      } //text
    } //-footerReserve
  } //link
  &.-arrow {
    position: relative;
    &:after {
      position: absolute;
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      @include mq-pc {
        width: rem(6);
        height: rem(10);
        top: calc(50% + 0.01rem);
        transform: translateY(-50%);
        right: rem(14.5);
      } //pc
      @include mq-sp {
        width: svw(6);
        height: svw(10);
        top: calc(50% + 0.5vw);
        transform: translateY(-50%);
        right: svw(14);
      } //sp
    } //after
    &.-arrowBtm {
      &:after {
        transform: rotate(90deg);
        @include mq-pc {
          top: calc(50% - 0.05rem);
        } //pc
        @include mq-sp {
          top: calc(50% - 1vw);
        } //sp
      } //after
    } //-arrowBtm
    &.-arrowBlack {
      &:after {
        background-image: url(/wp-content/themes/tetone/_assets/img/common/arrow_black.svg);
      } //after
      &:hover {
        &:after {
          background-image: url(/wp-content/themes/tetone/_assets/img/common/arrow_white.svg);
        } //after
      } //hover
    } //-arrowGreen
    &.-arrowGreen {
      &:after {
        background-image: url(/wp-content/themes/tetone/_assets/img/common/arrow_green.svg);
      } //after
      &:hover {
        &:after {
          background-image: url(/wp-content/themes/tetone/_assets/img/common/arrow_white.svg);
        } //after
      } //hover
    } //-arrowGreen
    &.-arrowWhite {
      &:after {
        background-image: url(/wp-content/themes/tetone/_assets/img/common/arrow_white.svg);
      } //after
      &:hover {
        &:after {
          background-image: url(/wp-content/themes/tetone/_assets/img/common/arrow_green.svg);
        } //after
      } //hover
    } //-arrowWhite
    img {
      width: 100%;
    }
  } //arrow
} //boxBtnItem

.viewMoreBtn {
  &.-wrap {
    display: flex;
    justify-content: flex-end;
  } //wrap
  &.-line {
    z-index: 1;
    overflow: hidden;
    @include mq-pc {
      margin-right: rem(7);
      top: rem((9 * 2)-1);
      width: rem(124.26);
      height: rem(1);
    } //pc
    @include mq-sp {
      margin-right: svw(7);
      top: svw((7.14 * 2)-1);
      width: svw(89.02);
      height: svw(1);
    } //sp
    &::before {
      content: " ";
      background: clr(black-main);
      display: block;
      @include mq-pc {
        width: rem(124.26);
        height: rem(1);
      } //pc
      @include mq-sp {
        width: svw(89.02);
        height: svw(1);
      } //sp
    } //before
  } //line
  &.-dot {
    position: absolute;
    content: " ";
    background: #373145;
    z-index: 1;
    @include mq-pc {
      width: rem(9);
      height: rem(9);
      border-radius: rem(50);
    } //pc
    @include mq-sp {
      width: svw(7.14);
      height: svw(7.14);
      border-radius: svw(7.14);
    } //sp
  } //dot
  &.-txt {
    font-family: $enfont;
    letter-spacing: 0.1em;
    @include mq-pc {
      font-size: rem(18);
      margin-top: rem(-4);
    } //pc
    @include mq-sp {
      font-size: svw(15);
    } //sp
  } //txt
  &.-link {
    position: relative;
    display: inline-flex;
    align-items: center;
    &:hover {
      .-line {
        &::before {
          animation-duration: 0.8s;
          animation-name: pageTopLine;
          @keyframes pageTopLine {
            0% {
              transform: translateX(0);
            }
            25% {
              transform: translateX(-100%);
              background-color: clr(black-main);
            }
            26% {
              background-color: transparent;
            }
            27% {
              transform: translateX(100%);
            }
            40% {
              background-color: clr(black-main);
            }
            100% {
              transform: translateX(0);
            }
          }
        }
      }
    }
  } //link
} //viewMoreBtn
