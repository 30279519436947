/* utility CSS */
/** =5
 * ========================================
 * link styles
 * ========================================
 */

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  outline: none;
}
a {
  color: clr(link);
  text-decoration: none;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
  &.underline {
    text-decoration: underline !important;
    color: clr(link);
    &:hover {
      color: clr(link);
    }
  }
  &.noUnderline {
    text-decoration: none;
  }
  &.transition {
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
  }
  &.alpha:hover {
    opacity: 0.9;
  }
}
.underline {
  text-decoration: underline;
}
