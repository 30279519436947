@charset "UTF-8";

/* hoverTxt
============================================================= */

%hover-txt {
  @include fadeover;
}
.hover-effect {
  &.is-txt {
    &:hover {
      .hover-txt {
        @extend %hover-txt;
        opacity: 0.5;
      }
    } //hover
    .hover-txt {
      transition: opacity, 0.5s;
    }
  }
} //hover-effect
