/* utility CSS */
/** =2-2
   * padding
   * ----------------------------------
   */

.u-pt5 {
  padding-top: 5px !important;
}
.u-pt10 {
  padding-top: 10px !important;
}
.u-pt15 {
  padding-top: 15px !important;
}
.u-pt20 {
  padding-top: 20px !important;
}
.u-pt30 {
  padding-top: 30px !important;
}
.u-pt40 {
  padding-top: 40px !important;
}
.u-pt50 {
  padding-top: 50px !important;
}
.u-pt60 {
  padding-top: 60px !important;
}
.u-pt70 {
  padding-top: 70px !important;
}
.u-pt80 {
  padding-top: 80px !important;
}
.u-pt100 {
  padding-top: 100px !important;
}
.u-pt120 {
  padding-top: 120px !important;
}
.u-pt150 {
  padding-top: 150px !important;
}

.u-pt05em {
  padding-top: 0.5em !important;
}
.u-pt10em {
  padding-top: 1em !important;
}
.u-pt15em {
  padding-top: 1.5em !important;
}
.u-pt20em {
  padding-top: 2em !important;
}

.u-pb5 {
  padding-bottom: 5px !important;
}
.u-pb10 {
  padding-bottom: 10px !important;
}
.u-pb15 {
  padding-bottom: 15px !important;
}
.u-pb20 {
  padding-bottom: 20px !important;
}
.u-pb30 {
  padding-bottom: 30px !important;
}
.u-pb40 {
  padding-bottom: 40px !important;
}
.u-pb50 {
  padding-bottom: 50px !important;
}
.u-pb60 {
  padding-bottom: 60px !important;
}
.u-pb70 {
  padding-bottom: 70px !important;
}
.u-pb80 {
  padding-bottom: 80px !important;
}
.u-pb100 {
  padding-bottom: 100px !important;
}
.u-pb120 {
  padding-bottom: 120px !important;
}
.u-pb150 {
  padding-bottom: 150px !important;
}

.u-pb05em {
  padding-bottom: 0.5em !important;
}
.u-pb10em {
  padding-bottom: 1em !important;
}
.u-pb15em {
  padding-bottom: 1.5em !important;
}
.u-pb20em {
  padding-bottom: 2em !important;
}
.u-pl10 {
  padding-left: 1rem !important;
}
.u-pl20 {
  padding-left: 2rem !important;
}

@include mq-sp {
  .u-spPb0 {
    padding-bottom: 0 !important;
  }

  .u-spPt5 {
    padding-top: 5px !important;
  }
  .u-spPt10 {
    padding-top: 10px !important;
  }
  .u-spPt15 {
    padding-top: 15px !important;
  }
  .u-spPt20 {
    padding-top: 20px !important;
  }
  .u-spPt30 {
    padding-top: 30px !important;
  }
  .u-spPt40 {
    padding-top: 40px !important;
  }
  .u-spPt50 {
    padding-top: 50px !important;
  }
  .u-spPt60 {
    padding-top: 60px !important;
  }
  .u-spPt70 {
    padding-top: 70px !important;
  }
  .u-spPt80 {
    padding-top: 80px !important;
  }
  .u-spPt100 {
    padding-top: 100px !important;
  }
  .u-spPt120 {
    padding-top: 120px !important;
  }
  .u-spPt150 {
    padding-top: 150px !important;
  }

  .u-spPt05em {
    padding-top: 0.5em !important;
  }
  .u-spPt10em {
    padding-top: 1em !important;
  }
  .u-spPt15em {
    padding-top: 1.5em !important;
  }
  .u-spPt20em {
    padding-top: 2em !important;
  }

  .u-spPb5 {
    padding-bottom: 5px !important;
  }
  .u-spPb10 {
    padding-bottom: 10px !important;
  }
  .u-spPb15 {
    padding-bottom: 15px !important;
  }
  .u-spPb20 {
    padding-bottom: 20px !important;
  }
  .u-spPb30 {
    padding-bottom: 30px !important;
  }
  .u-spPb40 {
    padding-bottom: 40px !important;
  }
  .u-spPb50 {
    padding-bottom: 50px !important;
  }
  .u-spPb60 {
    padding-bottom: 60px !important;
  }
  .u-spPb70 {
    padding-bottom: 70px !important;
  }
  .u-spPb80 {
    padding-bottom: 80px !important;
  }
  .u-spPb100 {
    padding-bottom: 100px !important;
  }
  .u-spPb120 {
    padding-bottom: 120px !important;
  }
  .u-spPb150 {
    padding-bottom: 150px !important;
  }

  .u-spPb05em {
    padding-bottom: 0.5em !important;
  }
  .u-spPb10em {
    padding-bottom: 1em !important;
  }
  .u-spPb15em {
    padding-bottom: 1.5em !important;
  }
  .u-spPb20em {
    padding-bottom: 2em !important;
  }

  .u-spPl0 {
    padding-left: 0 !important;
  }
  .u-spPr0 {
    padding-right: 0 !important;
  }
  .u-spPl20 {
    padding-left: 2rem !important;
  }
  .u-spPl30 {
    padding-left: 3rem !important;
  }
  .u-spPr20 {
    padding-right: 2rem !important;
  }
  .u-spPl35 {
    padding-left: 35px !important;
  }
  .u-spPr35 {
    padding-right: 35px !important;
  }
  .u-spPl40 {
    padding-left: 4rem !important;
  }
  .u-spPr40 {
    padding-right: 4rem !important;
  }
}
