@charset "UTF-8";

/* commonLayout
============================================================= */
.accessItem {
  &.-map {
    position: relative;
    width: 100%;
    overflow: hidden;
    iframe {
      width: 100%;
      @include mq-pc {
        height: rem(405);
      } //pc
      @include mq-sp {
        height: svw(240);
      } //sp
    } //iframe
  } //map
} //accessItem
