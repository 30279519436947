@charset "UTF-8";

/* hoverslide 
============================================================= */

.hover-effect {
  position: relative;
} //hover-effect
.is-slide {
  &:after {
    opacity: 0;
    content: "";
    display: inline-block;
    position: absolute;
    transition: opacity, 0.5s;
    @include mq-max {
      top: rem(15);
      right: rem(10);
      width: rem(276);
      height: rem(276);
    } //wide
    @include mq-pc {
      top: vw(15);
      right: vw(10);
      width: vw(276);
      height: vw(276);
      background-color: clr(green-main);
    }
  } //after
} //is-slide
