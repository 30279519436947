@charset "UTF-8";

/* title
============================================================= */

.pageTitle {
  &.-ja {
    font-family: $basefont;
  }
  &.-en {
    font-family: $enfont;
  }
  &.__titleLevel {
  } //__titleLevel
} //pageTitle
.sectionTitle {
  &.-ja {
    font-family: $basefont;
  }
  &.-en {
    font-family: $enfont;
  }
  &.__titleLevel {
    &01 {
      letter-spacing: 0.06em;
      @include mq-pc {
        font-size: rem(24);
        line-height: (28/24);
      } //pc
      @include mq-sp {
        font-size: svw(50);
        line-height: (70/50);
      } //sp
    } //01
    &Sub {
      color: clr(gray);
      letter-spacing: 0.05em;
      @include mq-pc {
        margin-top: rem(12);
        font-size: rem(16);
        line-height: (14/16);
      } //pc
      @include mq-sp {
        margin-top: svw(20);
        font-size: svw(30);
        line-height: (39.2/30);
      } //sp
    } //Sub
  } //__titleLevel
  &.-wrap {
    &.-colum {
      writing-mode: vertical-rl;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    } //colum
  } //-wrap
} //sectionTitle
