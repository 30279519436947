/* utility CSS */
/* color */
.bgcGray {
  background-color: clr(gray);
}
.bgColorMain {
  background-color: clr(bgColorMain);
}
.bgColorMainLight {
  background-color: clr(bgColorLight);
}
.bgColorMainDark {
  background-color: clr(bgColorDark);
}
.bgColorGray {
  background-color: clr(bgColorGray);
}
.bgColorGrayLight {
  background-color: clr(bgColorGrayLight);
}
.bgColorGrayDark {
  background-color: clr(bgColorGrayDark);
}
[class*="bgColor"] {
  padding: 1em 1.5em;
  box-sizing: border-box;
}
.-colorWhite {
  color: white;
} //-colorWhite
.-colorGray {
  color: clr(gray);
} //-colorGray
.-colorGreen {
  color: clr(green-main);
} //-colorGreen
