@charset "UTF-8";

/* block
============================================================= */

/* mainVisualBlock */

.mainVisualBlock {
  img {
    width: 100%;
  }

  + * {
    @include mq-pc {
      margin-top: rem(80);
    }

    @include mq-sp {
      margin-top: vw(50);
    }
  }
}

/* linkBlock */

.linkBlockWrap {
  @include mq-pc {
    display: flex;
    justify-content: space-between;
    padding-right: rem(102);
    padding-left: rem(102);
    margin-top: rem(220);
  }

  @include mq-sp {
    margin-top: vw(110);
    padding: 0 vw($sp-pdg);
  }

  &.-right {
    @include mq-pc {
      padding-left: rem(192);
    }
  }
}

.linkBlock {
  @include mq-pc {
    width: rem(248);
    height: rem(248);
  }

  @include mq-sp {
    &:not(:last-child) {
      margin-bottom: vw(34);
    }
  }
}

.linkBlock__link {
  border: 1px solid #343434;
  display: flex;
  text-decoration: none;
  width: 100%;
  height: 100%;

  &:hover {
    .moreLink {
      &.-right {
        > {
          ::after {
            right: 0;
          }
        }
      }
    }
  }

  @include mq-pc {
    justify-content: center;
    padding-top: rem(64);
  }

  @include mq-sp {
    padding: vw(27) vw(10) vw(27) vw(54);

    .moreLink {
      margin-top: vw(20);
    }
  }

  @include mq-hover {
    &:hover {
      .moreLink {
        > * {
          &::before {
            width: 0;
          }
          &::after {
            width: rem(72);
          }
        }
      }
    }
  }
}

.linkBlock__inner {
}

.linkBlock__titleSub {
  letter-spacing: 0.05em;

  @include mq-pc {
    font-size: rem(11);
  }

  @include mq-sp {
    font-size: vw(10);
  }

  + * {
    @include mq-pc {
      margin-top: rem(12);
    }
    @include mq-sp {
      margin-top: vw(8);
    }
  }
}

.linkBlock__title {
  letter-spacing: 0.045em;

  @include mq-pc {
    font-size: rem(20);
  }

  @include mq-sp {
    font-size: vw(21);
  }
}
