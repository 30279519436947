@charset "UTF-8";

/* accordion
============================================================= */
$icon-minusLong_pc: 14.21;
$icon-minusShort_pc: 1;
$icon-minusPos_pc: 0.9;
$icon-minusLong_sp: 20;
$icon-minusShort_sp: 2;
$icon-minusPos_sp: 20;
$icon-cicleW_pc: 41.27;
$icon-cicleW_sp: 28;

%icon-plusMinus {
  display: block;
  content: "";
  background-color: clr(green-main);
  position: absolute;
  right: 0;
}

.icon {
  &-pos {
    position: relative;
  }
  &-minus {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    display: inline-block;
    right: 0;
    @include mq-pc {
      // width: vw($icon-minusLong_pc);
      // height: vw($icon-minusLong_pc);
    } //pc
    @include mq-max {
      // width: rem($icon-minusLong_pc);
      // height: rem($icon-minusLong_pc);
    } //wide
    @include mq-sp {
      // width: svw($icon-minusLong_sp);
      // height: svw($icon-minusLong_sp);
    } //sp
    &:after {
      @extend %icon-plusMinus;
      left: 0;
      transition: opacity 0.5s;
      @include mq-pc {
        width: vw($icon-minusLong_pc);
        height: vw($icon-minusShort_pc);
        top: vw($icon-minusLong_pc/2);
      } //pc
      @include mq-max {
        width: rem($icon-minusLong_pc);
        height: rem($icon-minusShort_pc);
        top: rem($icon-minusLong_pc);
      } //wide
      @include mq-sp {
        width: svw($icon-minusLong_sp);
        height: svw($icon-minusShort_sp);
        top: svw($icon-minusLong_sp);
      } //sp
    } //after
    &:before {
      opacity: 0;
      @extend %icon-plusMinus;
      top: 0;
      transition: opacity 0.5s;
      @include mq-pc {
        width: vw($icon-minusShort_pc);
        height: vw($icon-minusLong_pc);
        left: vw(($icon-minusLong_pc/2)-0.5);
      } //pc
      @include mq-max {
        width: rem($icon-minusShort_pc);
        height: rem($icon-minusLong_pc);
        left: rem(($icon-minusLong_pc/2)-0.5);
      } //wide

      @include mq-sp {
        width: svw($icon-minusShort_sp);
        height: svw($icon-minusLong_sp);
        left: svw(($icon-minusLong_sp/2)-0.5);
      } //sp
    } //before
  } //minus
  &-plus {
    &:before {
      opacity: 1;
    } //before
  } //plus

  &-circle {
    background-color: white;
    border-radius: 50%;
    @include mq-pc {
      width: vw($icon-cicleW_pc);
      height: vw($icon-cicleW_pc);
    } //pc
    @include mq-max {
      width: rem($icon-cicleW_pc);
      height: rem($icon-cicleW_pc);
    } //wide
    @include mq-sp {
      width: svw($icon-cicleW_sp);
      height: svw($icon-cicleW_sp);
    } //sp
    &.icon-plus,
    &.icon-minus {
      &:after,
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      } //before
    } //icon-plus
  } //-circle
} //icon
.js-accordion {
  &Trigger {
    cursor: pointer;
    &_sp {
      @include mq-sp {
        cursor: pointer;
      } //sp
    }
  }
  &Content {
    display: none;
    &_sp {
      @include mq-sp {
        display: none;
      } //sp
    }
  }
}
.js-accordion {
  &TriggerGnav {
    cursor: pointer;
  }
  &ContentGnav {
    display: none;
  }
}
.-iconFaq {
  position: relative;
  @include mq-pc {
    padding-left: rem(35.8+18);
  } //pc
  &::before {
    left: 0;
    color: clr(green-main);
    @include mq-pc {
      position: absolute;
      font-size: rem(24);
    } //pc
    @include mq-sp {
      position: relative;
      font-size: svw(20);
      display: flex;
    } //sp
  }
  &.-question {
    &::before {
      content: "Q";
      @include mq-sp {
        margin-bottom: svw(10);
      } //sp
    } //before
  } //-question
  &.-answer {
    &::before {
      content: "A";
      @include mq-pc {
        margin-top: rem(-(18/2));
      } //pc
    } //before
  } //-question
} //-iconFaq
