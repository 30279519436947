@charset "UTF-8";

/* treatment
============================================================= */
.treatmentItem {
  &.__titleLevelTreatment {
    border-bottom: 1px solid clr(black-light);
    @include mq-pc {
      width: rem(96);
      display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: rem(29.6);
      font-size: rem(15);
    } //pc
    @include mq-sp {
      margin-top: svw(2);
      font-size: svw(16);
      text-align: center;
    } //sp
    &.-topLevel {
      justify-content: flex-start;
      text-align: left;
      margin-right: auto;
      margin-left: unset;
      width: unset;
      display: inline-flex;
      @include mq-pc {
        font-size: rem(24);
      } //pc
      @include mq-sp {
        font-size: svw(24);
      } //sp
    } //topLevel
  } //__titleLevelTreatment
  &.-list {
    display: flex;
    @include mq-pc {
      margin-left: rem(-(40/2));
      margin-right: rem(-(40/2));
    } //pc
    @include mq-sp {
      flex-wrap: wrap;
      justify-content: center;
      margin: svw(-(36/2)) svw(-(18/2));
    } //sp
  } //list

  &.-item {
    @include mq-pc {
      width: calc(100% / 3);
      padding-left: rem(40/2);
      padding-right: rem(40/2);
    } //pc
    @include mq-sp {
      width: calc(100% / 2);
      padding: svw(36/2) svw(18/2);
    } //sp
  } //item
  &.-txtBox {
    letter-spacing: 0.05em;
    @include mq-pc {
      padding: rem(20) rem(30);
      background-color: white;
      font-size: rem(14);
    } //pc
    @include mq-sp {
      font-size: svw(13);
    } //sp
  } //txtBox
  &.-intro {
    @include mq-pc {
      font-size: rem(15);
      margin-bottom: rem(48.6);
    } //pc
    @include mq-sp {
      font-size: svw(15);
      margin-bottom: svw(40);
    } //sp
  } //-intro
} //treatmentItem
////----treatmentCommon---start----//////
.treatmentItem {
  &.-topLevel {
    @include mq-sp {
      margin-bottom: svw(52);
    } //sp
  } //-topLevel
  &.-intro {
    @include mq-pc {
      margin-bottom: rem(55);
    } //pc
  } //intro
} //treatmentItem
////----treatmentCommon---end----//////
