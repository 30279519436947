@charset "UTF-8";

/* -cms
============================================================= */

.-cms {
  .-underLink {
    color: clr(cmsLink);
  } //underLink
  .-img {
    width: 100%;
    @include mq-pc {
      margin-top: 4.7rem;
    }
    @include mq-sp {
      margin-top: (27 / 375 * 100) + vw;
    }
  }
}
