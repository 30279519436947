.section__pageNav {
  @include mq-pc {
    margin-bottom: 10rem;
  } //pc
  @include mq-sp {
    margin-bottom: (50 / 375 * 100) + vw;
  } //sp
  &.-borderSpBtm {
    .pageNavBtmItemList {
      @include mq-sp {
        margin-top: (30.5/ 375 * 100) + vw;
        border-top: 1px solid clr(borderPunctuation);
        padding-top: (25.5/ 375 * 100) + vw;
      }
    }
  } //borderSpBtm
} //section__pageNav
.pageNavBtmItem {
  position: relative;
  &.-itemLink {
    @include mq-pc {
      width: calc(100% / 4);
      padding-right: (6.5 / 1500 * 100) + vw;
      padding-left: (6.5 / 1500 * 100) + vw;
    }
    @media screen and (min-width: $pc-basis) {
      padding-right: 0.65rem;
      padding-left: 0.65rem;
    }

    @include mq-sp {
      width: calc(100% / 2);
      padding-right: (3 / 375 * 100) + vw;
      padding-left: (3 / 375 * 100) + vw;
      margin-bottom: (21.9 / 375 * 100) + vw;
    }
    img {
      transition-duration: 0.5s;
    }
    &:hover {
      .anotherWindowImg {
        img {
          opacity: 0;
        }
        &.-pcItem {
          background-image: url(/_assets/img/common/icon_anotherWindow-black.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 1rem;
          height: 1rem;
        } //-pcItem
      } //anotherWindowImg
    }
  }
  &List {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    @include mq-pc {
      margin-right: -(6.5 / 1500 * 100) + vw;
      margin-left: -(6.5 / 1500 * 100) + vw;
    }
    @media screen and (min-width: $pc-basis) {
      margin-right: -0.65rem;
      margin-left: -0.65rem;
    }
    @include mq-sp {
      margin-right: -(3 / 375 * 100) + vw;
      margin-left: -(3 / 375 * 100) + vw;
    }
  }

  .figcaption {
    width: 100%;
    @include mq-pc {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      z-index: 2;
    } //pc
    @include mq-sp {
      margin-top: (9 / 375 * 100) + vw;
    } //sp
    &Txt {
      &.-en {
        font-family: $enfont;
        @include mq-pc {
          font-size: (32 / 1500 * 100) + vw;
          letter-spacing: 0.03em;
          line-height: 1.219;
        } //pc
        @media screen and (min-width: $pc-basis) {
          font-size: 3.1rem;
        }
        @include mq-sp {
          font-size: (18 / 375 * 100) + vw;
          letter-spacing: 0.05em;
          line-height: 0.944;
          color: #4a4a4a;
        } //sp
      } //en
      &.-ja {
        font-family: $basefont;
        @include mq-pc {
          font-size: (12.5 / 1500 * 100) + vw;
          letter-spacing: 0.071em;
          // line-height: 2.96;
        } //pc
        @media screen and (min-width: $pc-basis) {
          font-size: 1.25rem;
        }

        @include mq-sp {
          font-size: (10 / 375 * 100) + vw;
          letter-spacing: 0.04em;
          line-height: 1.8;
          color: #797979;
        } //sp
      } //ja
    } //Txt
  } //figcaption
} //pageNavBtmItem
