/* utility CSS */
/* Width
------------------------------------------------------------------ */
.u-w5 {
  width: 5% !important;
}

.u-w10 {
  width: 10% !important;
}

.u-w15 {
  width: 15% !important;
}

.u-w20 {
  width: 20% !important;
}

.u-w25 {
  width: 25% !important;
}

.u-w30 {
  width: 30% !important;
}

.u-w35 {
  width: 35% !important;
}

.u-w40 {
  width: 40% !important;
}

.u-w45 {
  width: 45% !important;
}

.u-w50 {
  width: 50% !important;
}

.u-w55 {
  width: 55% !important;
}

.u-w60 {
  width: 60% !important;
}

.u-w65 {
  width: 65% !important;
}

.u-w70 {
  width: 70% !important;
}

.u-w75 {
  width: 75% !important;
}

.u-w80 {
  width: 80% !important;
}

.u-w85 {
  width: 85% !important;
}

.u-w90 {
  width: 90% !important;
}

.u-w95 {
  width: 95% !important;
}

.u-w100 {
  width: 100% !important;
}
@include mq-pc {
  .u-pcw5 {
    width: 5% !important;
  }

  .u-pcw10 {
    width: 10% !important;
  }

  .u-pcw15 {
    width: 15% !important;
  }

  .u-pcw20 {
    width: 20% !important;
  }

  .u-pcw25 {
    width: 25% !important;
  }

  .u-pcw30 {
    width: 30% !important;
  }

  .u-pcw35 {
    width: 35% !important;
  }

  .u-pcw40 {
    width: 40% !important;
  }

  .u-pcw45 {
    width: 45% !important;
  }

  .u-pcw50 {
    width: 50% !important;
  }

  .u-pcw55 {
    width: 55% !important;
  }

  .u-pcw60 {
    width: 60% !important;
  }

  .u-pcw65 {
    width: 65% !important;
  }

  .u-pcw70 {
    width: 70% !important;
  }

  .u-pcw75 {
    width: 75% !important;
  }

  .u-pcw80 {
    width: 80% !important;
  }

  .u-pcw85 {
    width: 85% !important;
  }

  .u-pcw90 {
    width: 90% !important;
  }

  .u-pcw95 {
    width: 95% !important;
  }

  .u-pcw100 {
    width: 100% !important;
  }
}
@include mq-sp {
  .u-spw5 {
    width: 5% !important;
  }

  .u-spw10 {
    width: 10% !important;
  }

  .u-spw15 {
    width: 15% !important;
  }

  .u-spw20 {
    width: 20% !important;
  }

  .u-spw25 {
    width: 25% !important;
  }

  .u-spw30 {
    width: 30% !important;
  }

  .u-spw35 {
    width: 35% !important;
  }

  .u-spw40 {
    width: 40% !important;
  }

  .u-spw45 {
    width: 45% !important;
  }

  .u-spw50 {
    width: 50% !important;
  }

  .u-spw55 {
    width: 55% !important;
  }

  .u-spw60 {
    width: 60% !important;
  }

  .u-spw65 {
    width: 65% !important;
  }

  .u-spw70 {
    width: 70% !important;
  }

  .u-spw75 {
    width: 75% !important;
  }

  .u-spw80 {
    width: 80% !important;
  }

  .u-spw85 {
    width: 85% !important;
  }

  .u-spw90 {
    width: 90% !important;
  }

  .u-spw95 {
    width: 95% !important;
  }

  .u-spw100 {
    width: 100% !important;
  }
}
.-w100per {
  width: 100%;
}
