/* utility CSS */
/** =2-1
   * margin
   * ----------------------------------
   */
.u-mb0 {
  margin-bottom: 0px;
}
.u-mb5 {
  margin-bottom: 0.347vw !important;
}
.u-mb10 {
  margin-bottom: 0.694vw !important;
}
.u-mb15 {
  margin-bottom: 1.042vw !important;
}
.u-mb20 {
  margin-bottom: 1.389vw !important;
}
.u-mb25 {
  margin-bottom: 1.736vw !important;
}
.u-mb30 {
  margin-bottom: 2.083vw !important;
}
.u-mb40 {
  margin-bottom: 2.778vw !important;
}
.u-mb50 {
  margin-bottom: 3.472vw !important;
}
.u-mb60 {
  margin-bottom: 4.167vw !important;
}
.u-mb70 {
  margin-bottom: 4.861vw !important;
}
.u-mb80 {
  margin-bottom: 5.556vw !important;
}
.u-mb90 {
  margin-bottom: 6.25vw !important;
}
.u-mb100 {
  margin-bottom: 6.944vw !important;
}
.u-mb110 {
  margin-bottom: 7.639vw !important;
}
.u-mb120 {
  margin-bottom: 8.333vw !important;
}
.u-mb150 {
  margin-bottom: 10.417vw !important;
}
.u-mb160 {
  margin-bottom: 11.111vw !important;
}

// .u-mb0 {
//   margin-bottom: 0px !important;
// }
// .u-mb5 {
//   margin-bottom: 5px !important;
// }
// .u-mb10 {
//   margin-bottom: 10px !important;
// }
// .u-mb15 {
//   margin-bottom: 15px !important;
// }
// .u-mb20 {
//   margin-bottom: 20px !important;
// }
// .u-mb25 {
//   margin-bottom: 25px !important;
// }
// .u-mb30 {
//   margin-bottom: 30px !important;
// }
// .u-mb40 {
//   margin-bottom: 40px !important;
// }
// .u-mb50 {
//   margin-bottom: 50px !important;
// }
// .u-mb60 {
//   margin-bottom: 60px !important;
// }
// .u-mb70 {
//   margin-bottom: 70px !important;
// }
// .u-mb80 {
//   margin-bottom: 80px !important;
// }
// .u-mb90 {
//   margin-bottom: 90px !important;
// }
// .u-mb100 {
//   margin-bottom: 100px !important;
// }
// .u-mb110 {
//   margin-bottom: 110px !important;
// }
// .u-mb120 {
//   margin-bottom: 120px !important;
// }
// .u-mb150 {
//   margin-bottom: 150px !important;
// }
// .u-mb160 {
//   margin-bottom: 160px !important;
// }
.u-mb05em {
  margin-bottom: 0.5em !important;
}
.u-mb10em {
  margin-bottom: 1em !important;
}
.u-mb15em {
  margin-bottom: 1.5em !important;
}
.u-mb20em {
  margin-bottom: 2em !important;
}
.u-mb38rem {
  margin-bottom: 38rem !important;
}
@include mq-pc {
  .u-pcMb0 {
    margin-bottom: 0 !important;
  }
  .u-pcMb1 {
    margin-bottom: 1px !important;
  }
}
@include mq-sp {
  .u-spMb0 {
    margin-bottom: 0 !important;
  }
  .u-spMb5 {
    margin-bottom: 1.333vw !important;
  }
  .u-spMb10 {
    margin-bottom: 2.667vw !important;
  }
  .u-spMb15 {
    margin-bottom: 4vw !important;
  }
  .u-spMb20 {
    margin-bottom: 5.333vw !important;
  }
  .u-spMb25 {
    margin-bottom: 6.667vw !important;
  }
  .u-spMb30 {
    margin-bottom: 8vw !important;
  }
  .u-spMb35 {
    margin-bottom: 9.333vw !important;
  }
  .u-spMb40 {
    margin-bottom: 10.667vw !important;
  }
  .u-spMb50 {
    margin-bottom: 13.333vw !important;
  }
  .u-spMb60 {
    margin-bottom: 16vw !important;
  }
  .u-spMb70 {
    margin-bottom: 18.667vw !important;
  }
  .u-spMb80 {
    margin-bottom: 21.333vw !important;
  }
  .u-spMb90 {
    margin-bottom: 24vw !important;
  }
  .u-spMb100 {
    margin-bottom: 26.667vw !important;
  }
  .u-spMb120 {
    margin-bottom: 32vw !important;
  }
  .u-spMb150 {
    margin-bottom: 40vw !important;
  }

  // .u-spMb0 {
  //   margin-bottom: 0 !important;
  // }
  // .u-spMb5 {
  //   margin-bottom: 5px !important;
  // }
  // .u-spMb10 {
  //   margin-bottom: 10px !important;
  // }
  // .u-spMb15 {
  //   margin-bottom: 15px !important;
  // }
  // .u-spMb20 {
  //   margin-bottom: 20px !important;
  // }
  // .u-spMb25 {
  //   margin-bottom: 25px !important;
  // }
  // .u-spMb30 {
  //   margin-bottom: 30px !important;
  // }
  // .u-spMb35 {
  //   margin-bottom: 35px !important;
  // }
  // .u-spMb40 {
  //   margin-bottom: 40px !important;
  // }
  // .u-spMb50 {
  //   margin-bottom: 50px !important;
  // }
  // .u-spMb60 {
  //   margin-bottom: 60px !important;
  // }
  // .u-spMb70 {
  //   margin-bottom: 70px !important;
  // }
  // .u-spMb80 {
  //   margin-bottom: 80px !important;
  // }
  // .u-spMb90 {
  //   margin-bottom: 90px !important;
  // }
  // .u-spMb100 {
  //   margin-bottom: 100px !important;
  // }
  // .u-spMb120 {
  //   margin-bottom: 120px !important;
  // }
  // .u-spMb150 {
  //   margin-bottom: 150px !important;
  // }

  .u-spMb05em {
    margin-bottom: 0.5em !important;
  }
  .u-spMb10em {
    margin-bottom: 1em !important;
  }
  .u-spMb15em {
    margin-bottom: 1.5em !important;
  }
  .u-spMb20em {
    margin-bottom: 2em !important;
  }
  .u-spMb24rem {
    margin-bottom: 24rem !important;
  }
}
.u-mt0 {
  margin-top: 0px !important;
}
.u-mt5 {
  margin-top: 5px !important;
}
.u-mt10 {
  margin-top: 10px !important;
}
.u-mt20 {
  margin-top: 20px !important;
}
.u-mt30 {
  margin-top: 30px !important;
}
.u-mt40 {
  margin-top: 40px !important;
}
.u-mt50 {
  margin-top: 50px !important;
}
.u-mt60 {
  margin-top: 60px !important;
}
.u-mt70 {
  margin-top: 70px !important;
}
.u-mt-05 {
  margin-top: -5px !important;
}
.u-mt-10 {
  margin-top: -10px !important;
}

.u-ml-10em {
  margin-left: -1em !important;
}
.u-ml10 {
  margin-left: 1rem !important;
}
.u-ml20 {
  margin-left: 2rem !important;
}
.u-mr10 {
  margin-right: 1rem !important;
}
@include mq-pc {
  .u-pcMt0 {
    margin-top: 0 !important;
  }
  .u-pcMt10rem {
    margin-top: rem(10) !important;
  }
  .u-pcMt20rem {
    margin-top: rem(20) !important;
  }
  .u-pcMt30rem {
    margin-top: rem(30) !important;
  }
  .u-pcMt40rem {
    margin-top: rem(40) !important;
  }
  .u-pcMt50rem {
    margin-top: rem(50) !important;
  }
  .u-pcMt60rem {
    margin-top: rem(60) !important;
  }
}
@include mq-sp {
  .u-spMt0 {
    margin-top: 0 !important;
  }
  .u-spMt025 {
    margin-top: svw(2.5) !important;
  }
  .u-spMt10 {
    margin-top: svw(10) !important;
  }
  .u-spMt15 {
    margin-top: svw(15) !important;
  }
  .u-spMt20 {
    margin-top: svw(20) !important;
  }
  .u-spMt30 {
    margin-top: svw(30) !important;
  }
  .u-spMt40 {
    margin-top: svw(40) !important;
  }
  .u-spMt50 {
    margin-top: svw(50) !important;
  }
  .u-spMt60 {
    margin-top: svw(60) !important;
  }
  .u-spMt70 {
    margin-top: svw(70) !important;
  }
  .u-spMt-05 {
    margin-top: -5px !important;
  }
  .u-spMt-10 {
    margin-top: -10px !important;
  }
  .u-spMl50 {
    margin-left: 50px !important;
  }
}
