@charset "UTF-8";

/* triangle
============================================================= */
$triangleSideSize_pc: 5;
$triangleCenterSize_pc: 8;

$triangleSidePos_pc: 6;
$triangleCenterPos_pc: 6;

%linkTriangleBase {
  width: 0px;
  height: 0px;
  position: relative;
  transform: translateY(-50%);
  // 上下中央調整
  top: 50%;
  // top: calc(50% - #{(($triangleSize_pc / 2) + px)});
  display: inline-block;
  position: absolute;
}
%linkTriangleRight {
  border-left: ($triangleCenterSize_pc + px) solid clr(arrow);
  border-top: ($triangleSideSize_pc + px) solid transparent;
  border-bottom: ($triangleSideSize_pc + px) solid transparent;
}
%linkTriangleLeft {
  border-top: ($triangleSideSize_pc + px) solid transparent;
  border-right: ($triangleCenterSize_pc + px) solid clr(arrow);
  border-bottom: ($triangleSideSize_pc + px) solid transparent;
}
%linkTriangleBottom {
  border-left: ($triangleSideSize_pc + px) solid transparent;
  border-right: ($triangleSideSize_pc + px) solid transparent;
  border-top: ($triangleCenterSize_pc + px) solid clr(arrow);
}
%linkTriangleTop {
  border-left: ($triangleSideSize_pc + px) solid transparent;
  border-right: ($triangleSideSize_pc + px) solid transparent;
  border-bottom: ($triangleCenterSize_pc + px) solid clr(arrow);
}
%linkTriangleCenter {
  left: 50%;
}
.hasTriangle {
  position: relative;
  .icon-triangle {
    @extend %linkTriangleBase;
  }
  &.-right {
    .icon-triangle {
      @extend %linkTriangleRight;
      right: ($triangleSidePos_pc) + px;
    }
  }
  &.-left {
    .icon-triangle {
      @extend %linkTriangleLeft;
      left: ($triangleSidePos_pc) + px;
    }
  }
  &.-bottom {
    .icon-triangle {
      @extend %linkTriangleBottom;
      right: ($triangleSidePos_pc) + px;
    }
  }
  &.-top {
    .icon-triangle {
      @extend %linkTriangleTop;
      right: ($triangleSidePos_pc) + px;
    }
  }
  &.-center {
    .icon-triangle {
      @extend %linkTriangleCenter;
    }
    &.-bottom {
      .icon-triangle {
        bottom: ($triangleCenterPos_pc) + px;
        top: unset;
      }
    }
    &.-top {
      .icon-triangle {
        top: ($triangleCenterPos_pc) + px;
      }
    }
  }
}
