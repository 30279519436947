@charset "UTF-8";

/* arrow
============================================================= */
$arrowSize_pc: 10;
$arrowSidePos_pc: 6;
$arrowCenterPos_pc: 6;

%linkArrowBase {
  position: relative;
  width: ($arrowSize_pc) + px;
  height: ($arrowSize_pc) + px;
  border: 1px solid;
  transform: translateY(-50%);
  // 上下中央調整
  top: calc(50% - #{(($arrowSize_pc / 2) + px)});
  display: inline-block;
  position: absolute;
}
%linkArrowRight {
  border-color: clr(arrow) clr(arrow) transparent transparent;
  transform: rotate(45deg);
}
%linkArrowLeft {
  border-color: transparent transparent clr(arrow) clr(arrow);
  transform: rotate(45deg);
}
%linkArrowBottom {
  border-color: transparent transparent clr(arrow) clr(arrow);
  transform: rotate(-45deg);
}
%linkArrowTop {
  border-color: clr(arrow) clr(arrow) transparent transparent;
  transform: rotate(-45deg);
}
%linkArrowCenter {
  // transform: translateX(-50%);
  left: 50%;
}
.hasArrow {
  position: relative;
  .icon-arrow {
    @extend %linkArrowBase;
  }
  &.-right {
    .icon-arrow {
      @extend %linkArrowRight;
      right: ($arrowSidePos_pc) + px;
    }
  }
  &.-left {
    .icon-arrow {
      @extend %linkArrowLeft;
      left: ($arrowSidePos_pc) + px;
    }
  }
  &.-bottom {
    .icon-arrow {
      @extend %linkArrowBottom;
      right: ($arrowSidePos_pc) + px;
    }
  }
  &.-top {
    .icon-arrow {
      @extend %linkArrowTop;
      right: ($arrowSidePos_pc) + px;
    }
  }
  &.-center {
    .icon-arrow {
      @extend %linkArrowCenter;
    }
    &.-bottom {
      .icon-arrow {
        bottom: ($arrowCenterPos_pc) + px;
        top: unset;
      }
    }
    &.-top {
      .icon-arrow {
        top: ($arrowCenterPos_pc) + px;
      }
    }
  }
}
.-txtLinkGreen {
  color: clr(green-main);
} //txtLinkGreen
.-arrowLink {
  position: relative;
  display: inline-flex;
  align-items: center;
  &.-right {
    &::after {
      position: absolute;
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      @include mq-pc {
        width: rem(5);
        height: rem(9);
        right: rem(-10- (5/2));
      }
      @include mq-sp {
        width: svw(5);
        height: svw(9);
        right: svw(-10- (5/2));
      }
    } //after
  } //right
  &.-arrowGreen {
    &::after {
      background-image: url(/wp-content/themes/tetone/_assets/img/common/arrow-right_green.svg);
    } //after
  } //-arrowGreen
  &.-googleMap {
    @include mq-pc {
      font-size: rem(13);
      margin-top: rem(15);
    }
    @include mq-sp {
      font-size: svw(13);
      margin-top: svw(15);
    }
  } //-googleMap
} //arrowLink
