/* ------------------------------------------------------------------
//
// ========================================
// layout
// ========================================
*/
$col-max: 12; //何分割するか
$col-margin: 5px; //カラム間のmarginをpxにするか（あけたい間隔の半分）
$col-margin-wide: 10px; //カラム間のmargin（幅広）

//base
$col-margin-05: 5px; //カラム間のmargin
$col-margin-10: 10px; //カラム間のmargin
$col-margin-15: 15px; //カラム間のmargin
$col-margin-17-5: 17.5px; //カラム間のmargin
$col-margin-20: 20px; //カラム間のmargin
$col-margin-25: 25px; //カラム間のmargin
$col-margin-27-5: 27.5px; //カラム間のmargin
$col-margin-30: 30px; //カラム間のmargin
$col-margin-33: 33px; //カラム間のmargin
$col-margin-35: 35px; //カラム間のmargin
$col-margin-40: 40px; //カラム間のmargin
$col-margin-45: 45px; //カラム間のmargin
$col-margin-52: 52px; //カラム間のmargin
$col-margin-55: 55px; //カラム間のmargin
$col-margin-95: 95px; //カラム間のmargin

// vwを使用したい場合はこちら↓↓
//w-1400var(pc)
// $col-margin-05-pc: 0.347vw; //カラム間のmargin
// $col-margin-10-pc: 0.694vw; //カラム間のmargin
// $col-margin-15-pc: 1.042vw; //カラム間のmargin
// $col-margin-17-5-pc: 1.215vw; //カラム間のmargin
// $col-margin-20-pc: 1.389vw; //カラム間のmargin
// $col-margin-25-pc: 1.736vw; //カラム間のmargin
// $col-margin-30-pc: 2.083vw; //カラム間のmargin
// $col-margin-33-pc: 2.292vw; //カラム間のmargin
// $col-margin-35-pc: 2.431vw; //カラム間のmargin
// $col-margin-40-pc: 4rem; //カラム間のmargin
// $col-margin-45-pc: 3.125vw; //カラム間のmargin
// $col-margin-52-pc: 3.611vw; //カラム間のmargin
// $col-margin-95-pc: 6.597vw; //カラム間のmargin

//w-375var(sp)
$col-margin-05-sp: (5 / 375 * 100) + vw;
$col-margin-10-sp: (10 / 375 * 100) + vw;
$col-margin-15-sp: (15 / 375 * 100) + vw;
$col-margin-17-5-sp: (17.5 / 375 * 100) + vw;
$col-margin-20-sp: (20 / 375 * 100) + vw;
$col-margin-25-sp: (25 / 375 * 100) + vw;
$col-margin-27-5-sp: (27.5 / 375 * 100) + vw;
$col-margin-30-sp: (30 / 375 * 100) + vw;
$col-margin-33-sp: (33 / 375 * 100) + vw;
$col-margin-35-sp: (35 / 375 * 100) + vw;
$col-margin-40-sp: (40 / 375 * 100) + vw;
$col-margin-45-sp: (45 / 375 * 100) + vw;
$col-margin-52-sp: (52 / 375 * 100) + vw;
$col-margin-55-sp: (55 / 375 * 100) + vw;
$col-margin-95-sp: (95 / 375 * 100) + vw;

/**
* layout
* ----------------------------------
*/

.wrapColumn {
  display: flex;
  flex-wrap: wrap;

  > [class*="col"] {
    box-sizing: border-box;
  }

  &.pos-center {
    justify-content: center;
    @include mq-sp {
      &-sp {
        justify-content: center;
      }
    }
  }
  &.pos-right {
    justify-content: flex-end;
    @include mq-sp {
      &-sp {
        justify-content: flex-end;
      }
    }
  }
  &.pos-left {
    justify-content: end;
    @include mq-sp {
      &-sp {
        justify-content: end;
      }
    }
  }
  &.pos-space-between {
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }
  &.align-bottom {
    align-items: flex-end;
  }

  &.order-reverse {
    flex-direction: row-reverse;
  }

  &.has-margin {
    box-sizing: border-box;
    margin-right: -$col-margin;
    margin-left: -$col-margin;

    > [class*="col"] {
      padding-right: $col-margin;
      padding-left: $col-margin;
    }
  }

  &.has-margin-wide {
    box-sizing: border-box;
    margin-right: -$col-margin-wide;
    margin-left: -$col-margin-wide;

    > [class*="col"] {
      padding-right: $col-margin-wide;
      padding-left: $col-margin-wide;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-wide !important;
        margin-left: -$col-margin-wide !important;

        > [class*="col"] {
          padding-right: $col-margin-wide !important;
          padding-left: $col-margin-wide !important;
        }
      }
    } //sp-hasmaargin
  }

  &.has-margin-05 {
    box-sizing: border-box;
    margin-right: -$col-margin-05;
    margin-left: -$col-margin-05;

    > [class*="col"] {
      padding-right: $col-margin-05;
      padding-left: $col-margin-05;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-05-sp !important;
        margin-left: -$col-margin-05-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-05-sp !important;
          padding-left: $col-margin-05-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-10 {
    box-sizing: border-box;
    margin-right: -$col-margin-10;
    margin-left: -$col-margin-10;

    > [class*="col"] {
      padding-right: $col-margin-10;
      padding-left: $col-margin-10;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-10-sp !important;
        margin-left: -$col-margin-10-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-10-sp !important;
          padding-left: $col-margin-10-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-15 {
    box-sizing: border-box;
    margin-right: -$col-margin-15;
    margin-left: -$col-margin-15;

    > [class*="col"] {
      padding-right: $col-margin-15;
      padding-left: $col-margin-15;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-15-sp !important;
        margin-left: -$col-margin-15-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-15-sp !important;
          padding-left: $col-margin-15-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-17-5 {
    box-sizing: border-box;
    margin-right: -$col-margin-17-5;
    margin-left: -$col-margin-17-5;

    > [class*="col"] {
      padding-right: $col-margin-17-5;
      padding-left: $col-margin-17-5;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-17-5-sp !important;
        margin-left: -$col-margin-17-5-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-17-5-sp !important;
          padding-left: $col-margin-17-5-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-20 {
    box-sizing: border-box;
    margin-right: -$col-margin-20;
    margin-left: -$col-margin-20;

    > [class*="col"] {
      padding-right: $col-margin-20;
      padding-left: $col-margin-20;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-20-sp !important;
        margin-left: -$col-margin-20-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-20-sp !important;
          padding-left: $col-margin-20-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-25 {
    box-sizing: border-box;
    margin-right: -$col-margin-25;
    margin-left: -$col-margin-25;

    > [class*="col"] {
      padding-right: $col-margin-25;
      padding-left: $col-margin-25;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-25-sp !important;
        margin-left: -$col-margin-25-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-25-sp !important;
          padding-left: $col-margin-25-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-27-5 {
    box-sizing: border-box;
    margin-right: -$col-margin-27-5;
    margin-left: -$col-margin-27-5;

    > [class*="col"] {
      padding-right: $col-margin-27-5;
      padding-left: $col-margin-27-5;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-27-5-sp !important;
        margin-left: -$col-margin-27-5-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-27-5-sp !important;
          padding-left: $col-margin-27-5-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-30 {
    box-sizing: border-box;
    margin-right: -$col-margin-30;
    margin-left: -$col-margin-30;

    > [class*="col"] {
      padding-right: $col-margin-30;
      padding-left: $col-margin-30;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-30-sp !important;
        margin-left: -$col-margin-30-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-30-sp !important;
          padding-left: $col-margin-30-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-33 {
    box-sizing: border-box;
    margin-right: -$col-margin-33;
    margin-left: -$col-margin-33;

    > [class*="col"] {
      padding-right: $col-margin-33;
      padding-left: $col-margin-33;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-33-sp !important;
        margin-left: -$col-margin-33-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-33-sp !important;
          padding-left: $col-margin-33-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-35 {
    box-sizing: border-box;
    margin-right: -$col-margin-35;
    margin-left: -$col-margin-35;

    > [class*="col"] {
      padding-right: $col-margin-35;
      padding-left: $col-margin-35;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-35-sp !important;
        margin-left: -$col-margin-35-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-35-sp !important;
          padding-left: $col-margin-35-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-40 {
    box-sizing: border-box;
    margin-right: -$col-margin-40;
    margin-left: -$col-margin-40;

    > [class*="col"] {
      padding-right: $col-margin-40;
      padding-left: $col-margin-40;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-40-sp !important;
        margin-left: -$col-margin-40-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-40-sp !important;
          padding-left: $col-margin-40-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-45 {
    box-sizing: border-box;
    margin-right: -$col-margin-45;
    margin-left: -$col-margin-45;

    > [class*="col"] {
      padding-right: $col-margin-45;
      padding-left: $col-margin-45;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-45-sp !important;
        margin-left: -$col-margin-45-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-45-sp !important;
          padding-left: $col-margin-45-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-52 {
    box-sizing: border-box;
    margin-right: -$col-margin-52;
    margin-left: -$col-margin-52;

    > [class*="col"] {
      padding-right: $col-margin-52;
      padding-left: $col-margin-52;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-52-sp !important;
        margin-left: -$col-margin-52-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-52-sp !important;
          padding-left: $col-margin-52-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-55 {
    box-sizing: border-box;
    margin-right: -$col-margin-55;
    margin-left: -$col-margin-55;

    > [class*="col"] {
      padding-right: $col-margin-55;
      padding-left: $col-margin-55;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-55-sp !important;
        margin-left: -$col-margin-55-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-55-sp !important;
          padding-left: $col-margin-55-sp !important;
        }
      }
    } //sp-hasmaargin
  }
  &.has-margin-95 {
    box-sizing: border-box;
    margin-right: -$col-margin-95;
    margin-left: -$col-margin-95;

    > [class*="col"] {
      padding-right: $col-margin-95;
      padding-left: $col-margin-95;
    }
    @include mq-sp {
      &-sp {
        margin-right: -$col-margin-95-sp !important;
        margin-left: -$col-margin-95-sp !important;

        > [class*="col"] {
          padding-right: $col-margin-95-sp !important;
          padding-left: $col-margin-95-sp !important;
        }
      }
    } //sp-hasmaargin
  }
}

@for $i from 1 through $col-max {
  $col-num: $col-max / $i;
  .wrapColumn > .col-#{$i} {
    width: floor($i / $col-max * 10000%) / 100;
  }
  @include mq-sp {
    .wrapColumn > .col-sp-#{$i} {
      width: floor($i / $col-max * 10000%) / 100 !important;
    }
  }
  @include mq-pc {
    .wrapColumn > .col-tb-#{$i} {
      width: floor($i / $col-max * 10000%) / 100;
    }
  }
}
