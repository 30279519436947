@charset "UTF-8";

/* casesItem
============================================================= */
.casesItem {
  &.-list {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    overflow: hidden;
    @include mq-pc {
      margin: vw(-50/2);
    } //pc
    @include mq-max {
      margin: rem(-50/2);
    } //max
  } //list
  &.-item {
    @include mq-pc {
      width: calc(100% / 2);
      padding: vw(50/2);
    } //pc
    @include mq-max {
      padding: rem(50/2);
    } //max
    &:not(:last-child) {
      @include mq-sp {
        margin-bottom: svw(120);
      } //sp
    }
  } //item
  &.-txtBox {
    display: flex;
    flex-direction: column;
    @include mq-pc {
      margin-top: rem(18);
    } //pc
    @include mq-sp {
      margin-top: svw(30);
    } //sp
  } //txtBox
  &.-txt {
    @include mq-pc {
      font-size: rem(17);
      display: none;
    } //pc
    @include mq-sp {
      display: none;
    } //sp
  } //txt
  &.-title {
    @include mq-pc {
      font-size: rem(20);
      line-height: (25.2/20);
    } //pc
    @include mq-sp {
      font-size: svw(36);
      line-height: (70/36);
    } //sp
  } //title
  &.-date {
    color: clr(gray);
    @include mq-pc {
      font-size: rem(14);
      line-height: (28/14);
    } //pc
    @include mq-sp {
      font-size: svw(30);
      line-height: (39.2/30);
    } //sp
  } //date
} //casesItem
