@charset "UTF-8";

/* reservation
============================================================= */
.section__reservation {
  @include mq-pc {
    margin-top: rem(196);
  } //pc
  @include mq-sp {
    background-color: #eef1f0;
    padding-top: svw(40);
    padding-bottom: svw(50);
    margin-top: svw(60);
  } //sp

  .reservationItem {
    &.-inner {
      @include mq-pc {
        padding: rem(95) rem(84) rem(141);
        background-color: #eef1f0;
      } //pc
    } //inner
    &.-txt {
      @include mq-pc {
        margin-bottom: rem(60);
        width: rem(436);
        margin-left: auto;
        margin-right: auto;
        margin-top: rem(20);
      } //pc
      @include mq-sp {
        margin-bottom: svw(40);
        margin-top: svw(15);
      } //sp
    } //-txt
    &.-link {
      display: flex;
      justify-content: center;
      line-height: 1;
      letter-spacing: 0.1em;
      @include mq-pc {
        font-size: rem(24);
      } //pc
      @include mq-sp {
        font-size: svw(24);
      } //sp
    } //-link
    &.-list {
      display: flex;
      flex-wrap: wrap;
    } //list

    &.-item {
      background-color: white;
      text-align: center;
      @include mq-pc {
        width: calc(100% / 2.1);
        padding: rem(24.5) rem(24) rem(37.5);
      } //pc
      @include mq-sp {
        width: 100%;
        padding: svw(15.7) svw(17.4);
      } //sp
      &:nth-child(odd) {
        @include mq-pc {
          margin-right: rem(32);
        } //pc
      } //:nth-child(odd)
      &:nth-child(1) {
        @include mq-pc {
          margin-bottom: rem(32);
        } //pc
      } //:nth-child(1)
      &:nth-child(2) {
        @include mq-pc {
          margin-bottom: rem(32);
        } //pc
      } //:nth-child(1)
      &:not(:last-child) {
        @include mq-sp {
          margin-bottom: svw(10);
        } //sp
      } //not(:last-child)
    } //item
    &.-head {
      text-align: left;
      line-height: 1;
      @include mq-pc {
        font-size: rem(18);
      } //pc
      @include mq-sp {
        font-size: svw(14);
      } //sp
    } //head
    &.-itemTel {
      @include mq-sp {
        padding-bottom: svw(40);
      } //sp
      .-head {
        @include mq-pc {
          margin-bottom: rem(50);
        } //pc
        @include mq-sp {
          margin-bottom: svw(25.3);
        } //sp
      } //head
    } //-itemTel
    &.-itemWeb {
      @include mq-sp {
        padding-bottom: svw(30);
      } //sp
      .-head {
        @include mq-pc {
          margin-bottom: rem(39.5);
        } //pc
        @include mq-sp {
          margin-bottom: svw(30.3);
        } //sp
      } //head
      .boxBtnItem {
        margin-top: 0;
      } //boxBtnItem
    } //-itemWeb
    &.-itemLine {
      @include mq-sp {
        padding-bottom: svw(27);
      } //sp
      .-link {
        align-items: center;
      }
      .-img {
        &.-line {
          @include mq-pc {
            width: rem(75);
          } //pc
          @include mq-sp {
            width: svw(75);
          } //sp
        } //line
        &.-lineQr {
          @include mq-pc {
            width: rem(90);
          } //pc
          @include mq-sp {
            width: svw(90);
          } //sp
        } //lineQr
      } //img
      .-head {
        @include mq-pc {
          margin-bottom: rem(10);
        } //pc
        @include mq-sp {
          margin-bottom: svw(8);
        } //sp
      } //head

      .-lineWrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      } //-lineWrap
      .-lineItem {
        &:first-child {
          @include mq-pc {
            margin-right: rem(16/2);
          } //pc
          @include mq-sp {
            margin-right: svw(16/2);
          } //sp
        } //first-child
        &:last-child {
          @include mq-pc {
            margin-left: rem(16/2);
          } //pc
          @include mq-sp {
            margin-left: svw(16/2);
          } //sp
        } //last-child
      }
    } //-itemLine

    &.-itemHotPepper {
      @include mq-sp {
        padding-bottom: svw(27);
      } //sp

      .-img {
        @include mq-pc {
          width: rem(240);
        } //pc
        @include mq-sp {
          width: svw(173.71);
        } //sp
      } //img
      .-head {
        @include mq-pc {
          margin-bottom: rem(20);
        } //pc
        @include mq-sp {
          margin-bottom: svw(15.3);
        } //sp
      } //head
    } //-itemHotPepper
  } //reservationItem
} //section__reservation
