@charset "UTF-8";

/* default-fadeIn
============================================================= */
.js-loadFadeIn {
  opacity: 0;
  transition: 0.5s 0s;
}
.js-loadFadeIn.on {
  opacity: 1;
}
// .js-loadFadeIn {
//   opacity: 0;
//   // transition: all 3s;
// }

// .js-loadFadeIn {
//   // opacity: 1;
//   animation-name: loadFadeIn;
//   animation-duration: 3s;
// }
// @keyframes loadFadeIn {
//   0% {
//     opacity: 0;
//   }
//   40% {
//     opacity: 0.5;
//   }
//   100% {
//     opacity: 1;
//   }
// }
