@charset "UTF-8";

/* consultationHour
============================================================= */
.consultationHoursItem {
  &.-head {
    line-height: 1.3;
    display: flex;
    align-items: center;
    @include mq-pc {
      width: rem(120);
    } //pc
    @include mq-sp {
      width: svw(96);
    } //sp
  } //head
  &.-headWeekWrap {
    display: flex;
    @include mq-pc {
      padding-bottom: rem(15);
      margin-bottom: rem(15);
      font-size: rem(15);
      border-bottom: rem(1) solid clr(green-main);
    } //pc
    @include mq-sp {
      padding-bottom: svw(12.2);
      margin-bottom: svw(12.2);
      font-size: svw(12);
      border-bottom: svw(1) solid clr(green-main);
    } //sp
    &.-first {
      @include mq-pc {
        padding-bottom: rem(10);
      } //pc
      @include mq-sp {
        padding-bottom: svw(8.2);
        margin-top: svw(40.2);
      } //sp
    } //-first
  } //headWeekWrap
  &.-week {
    &.-list {
      display: flex;
      flex: 1;
      @include mq-pc {
        margin-left: rem(-(30/2));
        margin-right: rem(-(30/2));
      } //pc
      @include mq-sp {
        margin-left: svw(-(23/2));
        margin-right: svw(-(23/2));
      } //sp
    } //-list
    &.-item {
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq-pc {
        padding-left: rem(30/2);
        padding-right: rem(30/2);
      } //pc
      @include mq-sp {
        padding-left: svw(23/2);
        padding-right: svw(23/2);
      } //sp
    } //-item
  } //-week
} //consultationHoursItem
