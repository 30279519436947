@charset "UTF-8";

/* pagination
============================================================= */

.pagerItem {
  &.-box {
    position: relative;
    @include mq-pc {
      margin-top: rem(160);
    } //pc
    @include mq-sp {
      margin-top: svw(230);
    } //sp
  } //box
  &.-link {
    display: flex;
    align-items: center;
  } //link
  &.-list {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    @include mq-pc {
      margin-left: rem(-18/2);
      margin-right: rem(-18/2);
    } //pc
    @include mq-sp {
      margin-left: svw(-49/2);
      margin-right: svw(-49/2);
    } //sp
  } //link
  &.-item {
    color: clr(gray);
    .-link {
      color: clr(gray);
    } //link
    @include mq-pc {
      font-size: rem(17);
      padding-left: rem(18/2);
      padding-right: rem(18/2);
    } //pc
    @include mq-sp {
      font-size: svw(34);
      padding-left: svw(49/2);
      padding-right: svw(49/2);
    } //sp
    &.-current {
      color: clr(main);
    } //current
  } //-item
  &.-img {
    position: absolute;
    @include mq-pc {
      max-width: rem(66);
    } //pc
    @include mq-sp {
      max-width: svw(77.79);
    } //sp
    &.-prev {
      left: 0;
    } //-prev
    &.-next {
      right: 0;
    } //-next
  } //-img
} //pagerItem
