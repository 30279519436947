@charset "UTF-8";

/* form
============================================================= */

/** =10-4
   * formAdjust
   * ----------------------------------
   */
//common---start

/* プレースホルダーの色 */
::placeholder {
  color: clr(gray);
}
input:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset;
}

.checkboxWrap .checkbox + .icon-checkbox,
.checkboxWrap .checkbox + .icon-radio,
.checkboxWrap .radio + .icon-checkbox,
.checkboxWrap .radio + .icon-radio,
.checkboxWrap input[type="checkbox"] + .icon-checkbox,
.checkboxWrap input[type="checkbox"] + .icon-radio,
.checkboxWrap input[type="radio"] + .icon-checkbox,
.checkboxWrap input[type="radio"] + .icon-radio,
.radioWrap .checkbox + .icon-checkbox,
.radioWrap .checkbox + .icon-radio,
.radioWrap .radio + .icon-checkbox,
.radioWrap .radio + .icon-radio,
.radioWrap input[type="checkbox"] + .icon-checkbox,
.radioWrap input[type="checkbox"] + .icon-radio,
.radioWrap input[type="radio"] + .icon-checkbox,
.radioWrap input[type="radio"] + .icon-radio {
  bottom: 0;
}
input.txt,
input.-txt,
input[type="email"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea,
input,
.selectWrap select {
  color: clr(text);
  border-radius: unset;
  border-color: clr(formBorder);
  font-family: $basefont;
  // font-family: "Hiragino Mincho Pro", serif !important;
  // line-height: 2;
  letter-spacing: 0.1em;
  line-height: 1;
  @include mq-pc {
    font-size: rem(16);
    padding: rem(10) rem(14);
  }
  @include mq-sp {
    font-size: svw(15);
    padding: svw(10) svw(17);
  }
  &:focus {
    border-color: clr(gray);
  }
}
// select調整
.selectWrap select {
  position: relative;
  z-index: 2;
  background-color: unset;
}
.selectWrap {
  background-color: white;
}
.selectWrap,
select {
  cursor: pointer;
}
.selectWrap {
  @include mq-pc {
    max-width: rem(183.78);
    width: 100%;
  }
  @include mq-sp {
    width: svw(254);
  }
  &:after {
    content: "";
    background: url(/_assets/img/common/arrow-bottom_gray.svg);
    background-repeat: no-repeat;
    border: unset;
    transform: translateY(-50%);
    top: 50%;
    background-size: contain;
    @include mq-pc {
      right: rem(14);
      margin-top: rem((12/2)-2);
      width: rem(9);
      height: rem(11.68);
    }
    @include mq-sp {
      right: svw(14);
      margin-top: svw((12/2)-2);
      width: svw(9);
      height: svw(11.68);
    }
  } //after
} //selectWrap

textarea {
  @include mq-pc {
    height: rem(197);
  }
  @include mq-sp {
    height: svw(150);
  }
}
.checkboxWrap,
.radioWrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  &:not(:last-child) {
  }
}
.radioWrap,
.checkboxWrap {
  @include mq-pc {
    padding-right: rem(37/2);
    padding-left: rem(37/2);
    min-width: rem(180);
  }
  @include mq-sp {
    padding-right: svw(21.5/2);
    padding-left: svw(21.5/2);
    min-width: svw(40);
  }
}
.radioGroupWrap,
.checkboxGroupWrap {
  @include mq-pc {
    margin-bottom: rem(10);
    margin-right: rem(-(37/2));
    margin-left: rem(-(37/2));
  }
  @include mq-sp {
    margin-right: svw(-(21.5/2));
    margin-left: svw(-(21.5/2));
  }
  .radioGroupTitle,
  .checkboxGroupTitle {
    width: 100%;
    font-weight: 500;
    @include mq-pc {
      font-size: rem(14);
      margin-bottom: rem(5);
      padding-left: rem(18.5);
    }
    @include mq-sp {
      font-size: svw(14);
      padding-left: svw(12);
    }
  }
  &.-mbNon {
    @include mq-pc {
      margin-bottom: 0;
    }
  }
} //radioGroupWrap,checkboxGroupTitle
.-errorIcon {
  @include mq-pc {
    margin-right: rem(5);
  }
  @include mq-sp {
    margin-right: svw(5);
  }
} //errorIcon
.-errorTxt {
  background-color: clr(bgError);
  display: flex;
  align-items: center;
  width: 100%;
  @include mq-pc {
    padding: rem(10) rem(15);
    font-size: rem(14);
    margin-top: rem(10);
  }
  @include mq-sp {
    padding: svw(10) svw(15);
    font-size: svw(14);
    margin-top: svw(10);
  }
} //errorTxt
.fcError {
  // color: white;
  color: clr(errorTxt);
  // background-color: clr(errorTxt);
  line-height: 1;
}
.-required {
  position: relative;
  @include mq-pc {
    // font-size: rem(10);
    // top: rem(-7);
    right: rem(-2);
  }
  @include mq-sp {
    // font-size: svw(10);
    // top: svw(-7);
    right: svw(-2);
  }
}
.-errorStatement {
  width: 100%;
  background-color: clr(bgError);
  @include mq-pc {
    font-size: rem(12.5);
    margin-left: rem(222);
    padding: rem(8) rem(13);
    margin-top: rem(5);
  }
  @include mq-sp {
    font-size: svw(13);
    margin-right: $padding_rl-sp;
    margin-left: $padding_rl-sp;
    padding-right: svw(10);
    padding-left: svw(10);
    margin-top: svw(10);
  }
} //errorStatement
.-caution {
  line-height: 1.391;
  margin-top: rem(10);
  @include mq-pc {
    font-size: rem(11.5);
  }
  @include mq-sp {
    font-size: svw(11.5);
  }
}
.form {
  .formItem {
    width: 100%;
    @include mq-pc {
      font-size: rem(16);
    }
    @include mq-sp {
      font-size: svw(15);
    }

    &:not(:first-of-type) {
      @include mq-pc {
        margin-top: rem(30);
      }
      @include mq-sp {
        margin-top: svw(20);
      }
    } //&:not(:first-child)
  }
  .-formGroupItem {
    &:not(:first-child) {
      @include mq-pc {
        padding-top: rem(35);
      }
    } //&:not(:first-child)
    &:not(:last-child) {
      @include mq-pc {
        padding-bottom: rem(35);
      }
      @include mq-sp {
        margin-bottom: svw(20);
      }
    } //&:not(:last-child)
    &:last-child {
      @include mq-sp {
        padding-bottom: svw(20);
      }
    } //last-child
  } //-formItem
  .-borderBtm {
    border-bottom: 1px solid clr(borderBoldForm);
    &_pc {
      @include mq-pc {
        border-bottom: 1px solid clr(borderBoldForm);
      }
    }
    &.-adjust {
      margin-bottom: rem(10);
      @include mq-sp {
        padding-bottom: svw(10);
      }
    } //adjust
  } //-borderBtm
  .wrapColumn {
    &:not(:last-child) {
      @include mq-pc {
        margin-bottom: rem(48);
      } //pc
      @include mq-sp {
        margin-bottom: svw(40);
      } //sp
    } //:not(:last-child)
  } //wrapColumn
  .formField {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    label {
      font-weight: 500;
    } //label
    .formLabel {
      width: 100%;
      @include mq-pc {
        margin-right: rem(20);
        margin-bottom: rem(10);
        font-size: rem(15);
      }
      @include mq-sp {
        font-size: svw(15);
        margin-bottom: svw(7);
        // padding-top: svw(3);
        // padding-bottom: svw(3);
      }
    } //formLabel
    .formFieldRight {
      width: 100%;
      @include mq-pc {
        flex: 1;
      }
      @include mq-sp {
        padding-right: $padding_rl-sp;
        padding-left: $padding_rl-sp;
      }
    }
  } //formField
  .-addressWrap {
    display: flex;
    align-items: center;
    margin-bottom: rem(9);
    .postNo {
      font-size: rem(14.5);
      margin-right: rem(16);
    } //postNo
  } //addressWrap
} //form
.section__form {
  .btnWrap {
    @include mq-pc {
      margin-top: rem(52.5);
    }
    @include mq-sp {
      margin-top: svw(22.5);
    }
  } //btnWrap

  ///wp-パーツのレイアウト調整設定
  .screen-reader-response {
    color: clr(errorTxt);
    ul {
      display: none;
    } //ul
  } //screen-reader-response
  .wpcf7-not-valid-tip {
    color: clr(errorTxt);
  } //wpcf7-not-valid-tip
  .wpcf7-response-output {
    display: none;
  } //wpcf7-response-output
  .wpcf7c-conf {
    background-color: white;
  }
  .formBtn {
    &.-wrap {
      display: flex;
      align-items: center;
      @include mq-pc {
        width: rem(703);
        margin-left: rem(-(10/2));
        margin-right: rem(-(10/2));
        margin-top: rem(53);
      } //pc
      @include mq-sp {
        margin-left: svw(-(20/2));
        margin-right: svw(-(20/2));
        margin-top: svw(50);
      } //sp
    } //wrap
    &.-item {
      color: white;
      background-color: clr(green-main);
      cursor: pointer;
      transition: all 0.3s;
      @include mq-pc {
        border: rem(1) solid clr(green-main);
        width: rem(346);
        height: rem(50);
        font-size: rem(18);
        margin-left: rem(10/2);
        margin-right: rem(10/2);
      } //pc
      @include mq-sp {
        border: svw(1) solid clr(green-main);
        height: svw(48);
        font-size: svw(18);
        margin-left: svw(20/2);
        margin-right: svw(20/2);
      } //sp
      &.-confirmBtn {
        @include mq-sp {
          width: 100%;
        } //sp
      } //confirmBtn

      &.-sendBtn,
      &.-backBtn {
        @include mq-sp {
          width: calc(100% / 2);
        } //sp
      } //sendBtn
      &:hover {
        background-color: white;
        color: clr(green-main);
      } //hover
      &.back {
        background-color: white;
        color: clr(green-main);
        &:hover {
          background-color: clr(green-main);
          color: white;
        } //hover
      } //back
    } //item
    input {
    } //input
  } //formBtn
  .wpcf7c-force-hide {
    display: none !important;
  }
  .wpcf7-spinner {
    position: absolute;
  }
} //section__form
