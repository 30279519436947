@charset "UTF-8";

/* listDefAlternate
============================================================= */
$itemiTtleWidth_pc: 10;
$itemiTtleWidth_sp: (100 / 375 * 100);
$itemiTtleSpace-topBtm_pc: 1;
$itemiTtleSpace-topBtm_sp: (1 / 375 * 100);
$itemiTtleSpace-leftRight_pc: 1;
$itemiTtleSpace-leftRight_sp: (1 / 375 * 100);

.listDefAlternate {
  display: flex;
  flex-wrap: wrap;
  .itemTitle {
    font-weight: bold;
    display: flex;
    align-items: center;
    @include mq-pc {
      width: ($itemiTtleWidth_pc) + rem;
      padding: ($itemiTtleSpace-topBtm_pc) + rem ($itemiTtleSpace-leftRight_pc) +
        rem;
    }
    @include mq-sp {
      width: ($itemiTtleWidth_sp) + vw;
      padding: ($itemiTtleSpace-topBtm_pc) + vw ($itemiTtleSpace-leftRight_pc) +
        vw;
    }
  }
  .itemText {
    background-color: powderblue;
    @include mq-pc {
      width: calc(100% - #{($itemiTtleWidth_pc + rem)});
      padding: ($itemiTtleSpace-topBtm_pc) + rem ($itemiTtleSpace-leftRight_pc) +
        rem;
    }
    @include mq-sp {
      width: calc(100% - #{($itemiTtleWidth_sp + vw)});
      padding: ($itemiTtleSpace-topBtm_pc) + vw ($itemiTtleSpace-leftRight_pc) +
        vw;
    }
  }
  // sp_１行ずつ
  @include mq-sp {
    &.-spHorizon {
      display: flex;
      flex-wrap: wrap;

      .itemTitle,
      .itemText {
        width: 100%;
      }
    }
  }
  &.-hasborderBtm {
    .itemTitle,
    .itemText {
      &:not(:last-of-type) {
        border-bottom: 1px solid clr(border);
        margin-bottom: 1rem;
      }
    }
    &.-spHorizon {
      @include mq-sp {
        .itemTitle {
          margin-bottom: 0;
          border-bottom: 1px solid clr(border);
        }
      }
    } //-spHorizon
  } //hasborderBtm
}
