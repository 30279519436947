@charset "UTF-8";

/* ------------------------------------------------------------------
 body container wrapper main
------------------------------------------------------------------ */
.l-wrapper {
}
#header {
}
.-underLayer {
  .l-wrapper {
  } //l-wrapper
} //underLayer
// wrapper
.l-wrapper {
  position: relative;
}
// container
.l-container {
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
// main
.l-main {
  position: relative;
  // max-width: $wrapper-inner;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include mq-sp {
    // margin-top: svw(480);
  }
}
.l-inner {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  width: 100%;
  @include mq-pc {
    max-width: vw($content-inner + 40);
    padding-right: vw($padding_rl-l_pc);
    padding-left: vw($padding_rl-l_pc);
  } //pc
  @include mq-max {
    max-width: rem($content-inner);
    padding-right: unset;
    padding-left: unset;
  } //wide
  @include mq-sp {
    padding-right: svw($padding_rl-s_sp);
    padding-left: svw($padding_rl-s_sp);
  } //sp
}
.m-inner {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  width: 100%;
  @include mq-pc {
    max-width: rem($mediumContent-inner-pc + 40);
    padding-right: rem($padding_rl-l_pc);
    padding-left: rem($padding_rl-l_pc);
  } //pc
  @include mq-max {
    max-width: rem($mediumContent-inner-pc);
    padding-right: unset;
    padding-left: unset;
  } //wide
  @include mq-sp {
    padding-right: svw($padding_rl-s_sp);
    padding-left: svw($padding_rl-s_sp);
  } //sp
} //m-inner
.s-inner {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  width: 100%;
  @include mq-pc {
    max-width: rem($smallContent-inner-pc + 40);
    padding-right: rem($padding_rl-l_pc);
    padding-left: rem($padding_rl-l_pc);
  }
  @include mq-max {
    max-width: rem($smallContent-inner-pc);
    padding-right: unset;
    padding-left: unset;
  } //wide
  @include mq-sp {
    padding-right: svw($padding_rl-s_sp);
    padding-left: svw($padding_rl-s_sp);
  } //sp
} //s-inner
.footer-inner {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  width: 100%;
  @include mq-pc {
    max-width: vw(1440 + 40);
    padding-right: vw($padding_rl-l_pc);
    padding-left: vw($padding_rl-l_pc);
  }
  @include mq-max {
    max-width: rem(1440);
    padding-right: unset;
    padding-left: unset;
  } //wide
  @include mq-sp {
    padding-right: svw($padding_rl-s_sp);
    padding-left: svw($padding_rl-s_sp);
  } //sp
} //footer-inner
.paddingNon_sp {
  @include mq-sp {
    padding-right: 0;
    padding-left: 0;
  } //sp
}
