/* utility CSS */
/* Text Position */
.justifyCenter {
  justify-content: center !important;
}

.u-tac {
  text-align: center !important;
}

.u-tal {
  text-align: left !important;
}

.u-tar {
  text-align: right !important;
}

.u-vat {
  vertical-align: top !important;
}

.u-vam {
  vertical-align: middle !important;
}

.u-vab {
  vertical-align: bottom !important;
}

.u-vatb {
  vertical-align: text-bottom !important;
}

@include mq-pc {
  .u-pctac {
    text-align: center !important;
  }

  .u-pctal {
    text-align: left !important;
  }

  .u-pctar {
    text-align: right !important;
  }

  .u-pcvat {
    vertical-align: top !important;
  }

  .u-pcvam {
    vertical-align: middle !important;
  }

  .u-pcvab {
    vertical-align: bottom !important;
  }

  .u-pcvatb {
    vertical-align: text-bottom !important;
  }
}
@include mq-sp {
  .u-sptac {
    text-align: center !important;
  }

  .u-sptal {
    text-align: left !important;
  }

  .u-sptar {
    text-align: right !important;
  }

  .u-spvat {
    vertical-align: top !important;
  }

  .u-spvam {
    vertical-align: middle !important;
  }

  .u-spvab {
    vertical-align: bottom !important;
  }

  .u-spvatb {
    vertical-align: text-bottom !important;
  }
}
