@charset "UTF-8";
/* ------------------------------------------------------------------
 imageBlur
------------------------------------------------------------------ */
@keyframes imageBlur {
  0% {
    opacity: 1;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    -o-filter: blur(0px);
    filter: blur(0px);
  }
  50% {
    opacity: 1;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    -o-filter: blur(0px);
    filter: blur(0px);
  }
  100% {
    opacity: 0;
    -webkit-filter: blur(15px);
    -moz-filter: blur(15px);
    -ms-filter: blur(15px);
    -o-filter: blur(15px);
    filter: blur(15px);
  }
}
.-effectBlur {
  animation-name: imageBlur;
  opacity: 0;
  transition: 0.8s;
} //effectBlur
