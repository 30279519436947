@charset "UTF-8";

/* loadingAdjust
============================================================= */

/* loadingContainer
============================================================= */
body {
  &.-scrollbarNon {
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
    &::-webkit-scrollbar {
      /* Chrome, Safari 対応 */
      display: none;
    }
  }
}
#loadingContainer {
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  &.-hidden {
    opacity: 0;
    pointer-events: none;
  }
}
.loaderWrap {
  position: relative;
  width: 100%;
  height: 100%;
}
.loaderItem {
  opacity: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  img {
    height: 100%;
    width: 100%;
  }
}
.loadingLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;
  @include mq-pc {
    width: rem(300);
  }
  @include mq-sp {
  }
}
.loadingTxt {
  color: white;
  font-family: $enfont;
  letter-spacing: 0.025em;
}
.loaders {
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  @include mq-pc {
    font-size: 1.54rem;
    bottom: (50 / 1440 * 100) + vw;
  }
  @include mq-sp {
    font-size: (13.75 / 375 * 100) + vw;
    // bottom: (28 / 375 * 100) + vw;
    bottom: (28 / 375 * 100) + vw;
  }
}

.loaded {
  transition: opacity 0.25s linear;
  opacity: 1;
}
.ball-pulse > div {
  background-color: white;
  width: 2px;
  height: 2px;
  margin: 0.1px;
  border-radius: 50%;
}
.loaders {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  .loader {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
