@charset "UTF-8";

/* commonLayout
============================================================= */
.sectionTxt {
  letter-spacing: 0;
  @include mq-pc {
    line-height: (30.6/17);
    font-size: rem(17);
  } //pc
  @include mq-sp {
    line-height: (54.4/34);
    font-size: svw(34);
  } //sp
  &.-center_pc {
    @include mq-pc {
      text-align: center;
    } //pc
  } //-center_pc
} //sectionTxt
.commonLinkItem {
  &.-wrap {
  } //wrap
  &.-right {
    text-align: right;
    position: relative;
    @include mq-pc {
      right: vw(63);
    } //pc
    @include mq-max {
      right: rem(63);
    } //wide

    @include mq-sp {
      right: svw(90.2);
    } //sp
  } //-right
  &.-link {
    display: inline-flex;
    &.-ja {
      letter-spacing: 0;
      @include mq-pc {
        font-size: rem(17);
        line-height: (30.6/17);
      } //pc
      @include mq-sp {
        font-size: svw(34);
        line-height: (54.4/34);
      } //sp
    } //ja
    &.-en {
      letter-spacing: 0.05em;
      @include mq-pc {
        font-size: rem(18);
        line-height: (28/18);
      } //pc
      @include mq-sp {
        font-size: svw(36);
        line-height: (56/36);
      } //sp
    } //en
  } //link
  &.-arrow {
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      @include mq-pc {
        bottom: vw(-5);
      } //pc
      @include mq-max {
        bottom: rem(-5);
      } //wide
      @include mq-sp {
        bottom: svw(-8);
      } //sp
    } //after,before
    &:before {
      background-color: black;
      display: flex;
      @include mq-pc {
        width: calc(100% + #{vw(63)});
        height: vw(1);
      } //pc
      @include mq-max {
        width: calc(100% + #{rem(63)});
        height: rem(1);
      } //wide

      @include mq-sp {
        width: calc(100% + #{svw(90.2)});
        height: svw(2);
      } //sp
    } //before
    &:after {
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      @include mq-pc {
        background-image: url(/_assets/img/common/arrow_pc.svg);
        width: vw(8);
        height: vw(8);
        right: vw(-63);
      } //pc
      @include mq-max {
        width: rem(8);
        height: rem(8);
        right: rem(-63);
      } //wide
      @include mq-sp {
        background-image: url(/_assets/img/common/arrow_sp.svg);
        width: svw(30);
        height: svw(30);
        right: svw(-90.2);
      } //sp
    } //after
    &.-left {
      &:before {
        right: 0;
      } //before
      &:after {
        right: unset;
        @include mq-pc {
          background-image: url(/_assets/img/common/arrow-left_pc.svg);
          left: vw(-63);
        } //pc
        @include mq-max {
          left: rem(-63);
        } //wide
        @include mq-sp {
          background-image: url(/_assets/img/common/arrow-left_sp.svg);
          left: svw(-90.2);
        } //sp
      } //after
    } //left
    &.-center {
      @include mq-sp {
        font-size: svw(34);
      } //sp

      &:before {
        right: 0;
        left: 0;
        width: 100%;
      } //before
      &:after {
        right: unset;
        background-image: none;
        left: unset;
        right: unset;
        left: 50%;
        transform: translateX(-50%);
        @include mq-pc {
          width: vw(140);
          height: vw(1);
          background-color: black;
        } //pc
        @include mq-max {
          width: rem(140);
          height: rem(1);
        } //wide

        @include mq-sp {
          width: svw(193);
          height: svw(1);
        } //sp
      } //after
    } //center
    &.-full_sp {
      @include mq-sp {
        display: flex;
      } //sp
      &.-arrow {
        &:before {
          @include mq-sp {
            width: 100%;
          } //sp
        } //before
        &:after {
          @include mq-sp {
            right: 0;
          } //sp
        } //after
      }
    } //full_sp
  } //arrow
  &.-txtNone_sp {
    &.-arrow {
      &:before {
        @include mq-sp {
          width: svw(234.8+90.2);
        } //sp
      } //before
      &:after {
        content: "";
        @include mq-sp {
          right: svw(-(234.8+90.2));
        } //sp
      } //after
    } //arrow
  } //-txtNone_sp
} //commonLinkItem
body {
  &[id="material"],
  &[id="technics"],
  &[id="production"] {
    //-------common__start------------***
    // fvが背景文字上の場合の記述
    .-defaultImg {
      .-link {
        @include mq-sp {
          // background-image: url(/_assets/img/common/logo_white_sp.svg);
        } //sp
      } //link
    } //defaultImg
    .-fixedImg {
      .-link {
        @include mq-sp {
          background-image: url(/_assets/img/common/logo_black_fixed.svg);
        } //sp
      } //link
    } //fixedImg
    .-headerFixed {
      .menuBtnItem {
        span {
          background: black;
        } //span
      } //menuBtnItem
    } //-headerFixed
    .menuBtnItem {
      span {
        @include mq-pc {
          background-color: white;
        } //pc
      } //span
    } //menuBtnItem
    .languageBtnItem {
      .-link {
        @include mq-pc {
          color: white;
        } //pc
      } //link
      &.-jp {
        @include mq-pc {
          border-bottom: rem(1) solid white !important;
        } //pc
        @include mq-sp {
          border-bottom: svw(2) solid black !important;
        } //sp
      } //jp
    } //languageBtnItem
    .section {
      &:not(:last-child) {
        @include mq-pc {
          margin-bottom: rem(40);
        } //pc
      } //:not(:last-child)
    }
    .sectionTitle {
      &.-wrap {
        @include mq-pc {
          margin-bottom: rem(40);
        } //pc
        @include mq-sp {
          margin-bottom: svw(60);
        } //sp
      } //wrap
    } //sectionTitle
    .sectionTxtImgBox {
      &.-row {
        display: flex;
        @include mq-pc {
          align-items: flex-end;
        } //pc
        @include mq-sp {
          flex-direction: column;
        } //sp
        .sectionTxtBox {
          @include mq-pc {
            width: vw(658);
            padding-left: vw(72);
            padding-right: vw(64);
          } //pc
          @include mq-sp {
            padding-right: svw($padding_rl-s_sp);
            padding-left: svw($padding_rl-s_sp);
          } //sp
        } //sectionTxtBox
        .sectionImg {
          @include mq-pc {
            width: vw(782);
            height: 100vh;
          } //pc
        } //sectionImg
        &.-txtRightBox {
          @include mq-pc {
            flex-direction: row-reverse;
          } //pc
        } //txtRightBox
      } //-row
    } //sectionTxtImgBox
    .section {
      &CommonLinkBox {
        @include mq-pc {
          width: vw(658);
          padding-left: vw(72);
          padding-right: vw(64);
        } //pc

        @include mq-sp {
          margin-top: svw(25);
          padding-right: svw($padding_rl-s_sp);
          padding-left: svw($padding_rl-s_sp);
        } //sp
      } //CommonLinkBox
      .commonLinkItem {
        &.-box {
          display: flex;
        } //box
        &.-wrap {
          @include mq-pc {
            margin-top: rem(60);
          } //pc
          @include mq-sp {
            margin-top: svw(100);
          } //sp
          .-link {
            &:nth-child(2) {
              @include mq-pc {
                margin-left: rem(40.5 + 63 + 8);
              } //pc
            } //not(:last-child)
          } //-link
          &.-double {
            @include mq-sp {
              width: 100%;
              display: flex;
              justify-content: space-between;
            } //sp
            .-link {
              display: inline-flex;
              &:nth-child(2) {
                @include mq-sp {
                  left: svw(-(90.2));
                } //sp
              } //not(:last-child)
            } //link
          } //-double
        } //&.-wrap
        &.-link {
          letter-spacing: 0;
          flex-direction: column;
          @include mq-pc {
            font-size: rem(17);
          } //pc
          @include mq-sp {
            font-size: svw(34);
          } //sp
          &.-lineHeight1 {
            @include mq-pc {
              line-height: 1;
            } //pc
          } //lineHeight1
        } //-link
        &.-sub {
          color: clr(gray);
          @include mq-pc {
            font-size: rem(14);
            line-height: (28/14);
            margin-bottom: rem(2);
          } //pc
          @include mq-sp {
            font-size: svw(30);
            line-height: (39.2/30);
            margin-bottom: svw(10);
          } //sp
        } //-sub
      } //commonLinkItem
      &.-firstSection {
        @include mq-pc {
          padding-top: rem(359);
        } //pc
      } //-firstSection
      &.-picSection {
        .sectionImg {
          &:not(:last-child) {
            @include mq-sp {
              margin-bottom: svw(60);
            } //sp
          } //not(:last-child)
        } //sectionImg
        @include mq-sp {
          margin-bottom: svw(100);
        } //sp
      } //picSection
    } //section
    @include mq-pc {
      .bg {
        overflow: hidden;
        position: fixed;
        top: 0;
        right: 0;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0;
        transition: all 0.8s ease 0s;
        z-index: -1;
      }
      .show .bg {
        opacity: 1;
      }
    } //pc
    //-------common__end------------***
  } //&[id="material,technics,production"]
} //body
