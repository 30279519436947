@charset "UTF-8";

/* mediaquery
============================================================= */
//pc-basis+1以上
@mixin mq-pc_wide {
  @media screen and (min-width: $pc-basis + 1), print {
    @content;
  }
}

//768以上
@mixin mq-pc {
  @media screen and (min-width: $break-small + 1), print {
    @content;
  }
}
//1024以下
@mixin mq-tab {
  @media screen and (max-width: $break-tablet) {
    @content;
  }
}
//767以下
@mixin mq-sp {
  @media screen and (max-width: $break-small) {
    @content;
  }
}
//768以上
@mixin mq-min {
  @media screen and (min-width: $min-basis) {
    @content;
  }
}
//1440以上
@mixin mq-max {
  @media screen and (min-width: $max-basis), print {
    @content;
  }
}

@mixin mq-hover {
  @media (hover: hover),
    screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    @content;
  }
}

@function vw($size: 16) {
  $vw: $size * (100 / $pc-basis) + vw;
  @return $vw;
}
@function svw($size: 15) {
  $svw: $size * (100 / $sp-basis) + vw;
  @return $svw;
} /* hover
============================================================= */

@mixin fadeover {
  @include mq-hover {
    transition-duration: 0.3s;
    text-decoration: none !important;
    &:hover {
      opacity: 0.5;
    }
  }
}

/* other
============================================================= */

@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

@mixin contents {
  @include mq-pc {
    margin-left: auto;
    margin-right: auto;
    max-width: rem(1076);
  }
}

@mixin jp {
  html[lang="ja"] {
    @content;
  }
}

@mixin ie11($selector) {
  _:-ms-lang(x)::-ms-backdrop,
  #{$selector} {
    @content;
  }
}
