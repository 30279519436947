@charset "UTF-8";

/* staffItem
============================================================= */

.staffItem {
  &.-list {
    @include mq-pc {
    } //pc
  } //-list
  &.-item {
    @include mq-pc {
    } //pc

    &.-left {
      @include mq-pc {
        width: rem(360);
        margin-right: rem(116/2);
      } //pc
      @include mq-sp {
        width: svw(124);
      } //sp
    } //-left
    &.-right {
      flex: 1;
      @include mq-pc {
        margin-left: rem(116/2);
        padding-right: rem(84);
      } //pc
    } //-right
  } //-item

  .-title {
    &.-position {
      color: clr(green-main);
      letter-spacing: 0.05em;
      line-height: 1.5;
      @include mq-pc {
        font-size: rem(20);
        margin-bottom: rem(30);
      } //pc
      @include mq-sp {
        font-size: svw(15);
        text-align: right;
      } //sp
    } //-position
    &.-nameWrap {
      @include mq-pc {
        display: flex;
        align-items: baseline;
        margin-bottom: rem(43);
        flex-wrap: wrap;
      } //pc
    } //nameWrap
    &.-name {
      letter-spacing: 0.01em;
      @include mq-pc {
        font-size: rem(24);
        margin-right: rem(12);
      } //pc
      @include mq-sp {
        font-size: svw(24);
        text-align: right;
      } //sp
    } //-name
    &.-rubi {
      color: clr(green-main);
      letter-spacing: 0.05em;
      line-height: 1.5;
      @include mq-pc {
        font-size: rem(15);
      } //pc
      @include mq-sp {
        font-size: svw(15);
        text-align: right;
      } //sp
    } //-rubi
  } //title
  .section__txt {
    @include mq-sp {
      margin-top: svw(20);
    } //sp
  } //section__txt
  &.-infoDetail {
    width: 100%;
  } //infoDetail
  &.-list {
    display: flex;
    flex-wrap: wrap;
    &:not(:last-child) {
      @include mq-pc {
        margin-bottom: rem(112.8);
      } //pc
    }
    @include mq-sp {
      flex-wrap: wrap;
      align-items: flex-end;
    } //sp
  } //-list
  &.-listWrap {
    .-list {
      &:not(:last-child) {
        @include mq-pc {
          margin-bottom: rem(84);
        } //pc
        @include mq-sp {
          margin-bottom: svw(50);
        } //sp
      } //not(:last-child)

      &:nth-child(even) {
        flex-direction: row-reverse;
        .staffItem {
          &.-item {
            &.-left {
              margin-right: 0;
              @include mq-pc {
                margin-left: rem(116/2);
                margin-right: rem(84);
              } //pc
              @include mq-sp {
                margin-left: svw(19/2);
              } //sp
            } //-left

            &.-right {
              margin-left: 0;
              padding-right: 0;
              @include mq-pc {
                margin-right: rem(116/2);
              } //pc
              @include mq-sp {
                margin-right: svw(19/2);
              } //sp
            } //-right
          } //-item
          &.-name,
          &.-rubi,
          &.-position {
            @include mq-sp {
              text-align: left;
            } //sp
          } //&.-name,&.-rubi,&.-position
        } //staffItem
      } //:nth-child(even)
    } //-list
  } //-listWrap
  &.-careerList {
    width: 100%;
    @include mq-pc {
      margin-top: rem(82);
    } //pc
    @include mq-sp {
      margin-top: svw(15);
    } //sp
  } //careerList
  &.-careerItem {
    display: flex;
    @include mq-pc {
      border-bottom: rem(1) solid clr(green-main);
      padding-bottom: rem(40);
    } //pc
    @include mq-sp {
      flex-wrap: wrap;
      border-bottom: svw(1) solid clr(green-main);
      padding-bottom: svw(10.5);
    } //sp
    &:first-child {
      @include mq-pc {
        border-top: rem(1) solid clr(green-main);
        padding-top: rem(40);
      } //pc
      @include mq-sp {
        border-top: svw(1) solid clr(green-main);
        padding-top: svw(10.5);
      } //sp
    } //:first-child
    &:not(:last-child) {
      @include mq-pc {
        margin-bottom: rem(26);
      } //pc
      @include mq-sp {
        margin-bottom: svw(15.5);
      } //sp
    }
  } //careerItem
  &.-careerHead {
    font-weight: 500;
    width: 100%;
    @include mq-pc {
      max-width: rem(213);
      padding-right: rem(62);
    } //pc
    @include mq-sp {
      font-size: svw(14);
    } //sp
  } //careerHead
  &.-careerDetail {
    @include mq-pc {
      flex: 1;
    } //pc
    @include mq-sp {
      font-size: svw(14);
    } //sp
  } //-careerDetail
} //staffItem
