@charset "UTF-8";

/* swiperAdjust
============================================================= */
/*
* ------
* swiperの設定
*/
.swiper {
  &-container {
    &Wrap {
    } //Wrap
  } //-container
  &-wrapper {
  } //-wrapper
  &-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
} //swiper
.commonSwiper-button {
  transform: translateY(-50%);
  @include mq-pc {
    top: calc(50% - #{((($commonSwiper-button-size_pc / 2) * 0.1) + rem)});
    width: ($commonSwiper-button-size_pc / 1500 * 100) + vw;
    height: ($commonSwiper-button-size_pc / 1500 * 100) + vw;
  }
  @include mq-sp {
    top: calc(50% - #{((($commonSwiper-button-size_sp / 2) / 375 * 100) + vw)});
    width: ($commonSwiper-button-size_sp / 375 * 100) + vw;
    height: ($commonSwiper-button-size_sp / 375 * 100) + vw;
  }
  @include fadeover;
  &:hover {
    cursor: pointer;
  } //hover
  &-relativ {
    position: relative;
  } //relativ
  &-next {
    position: absolute;
    right: (35 / 1500 * 100) + vw;
    z-index: 2;
  } //-next
} //commonSwiper-buttonRelativ
.swiper-pagination {
  line-height: 1;
  @include mq-pc {
    bottom: vw(20) !important;
  } //pc
  @include mq-sp {
    text-align: unset !important;
    left: svw(24) !important;
    bottom: svw(30) !important;
  } //sp
} //swiper-pagination
.swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: transparent !important;
  @include mq-pc {
    width: rem(9) !important;
    height: rem(9) !important;
    border: 1px solid white !important;
    margin: 0 rem(26/2) !important;
  } //pc
  @include mq-sp {
    width: svw(9) !important;
    height: svw(9) !important;
    border: svw(1) solid white !important;
    margin: 0 svw(18/2) !important;
  } //sp
  &-active {
    background-color: white !important;
  } //-active
}
